import {Router} from '@reach/router'
import PropTypes from 'prop-types'

/* Escape hatch to overcome Reach Routers div interfering div */
const DropProps = ({children}) => <>{children}</>
DropProps.propTypes = {
  children: PropTypes.node,
}

const ReachRouter = ({...props}) => {
  return (
    <Router component={DropProps} {...props} />
  )
}

ReachRouter.propTypes = {
}

export default ReachRouter
