import {useTranslation} from 'react-i18next'
import PropTypes from 'prop-types'
import * as patterns from '../../../../../constants/patterns'
import mapValuesDeep from '../../../../lib/mapValuesDeep'
import {FormSection} from '../../../../uikit'
import {showErrorOnBlur, validatePatterns} from '../../../utils/forms'
import TextInput from '../../form/TextInput'
import withForm from '../../form/withForm'
import Button from '../../containers/Button'


const validate = (values, {t}) => {
  const errors = {}

  validatePatterns(errors, values, {
    email: [patterns.EMAIL, 'validations.email'],
  })

  return mapValuesDeep(errors, (key) => t(key))
}

const PasswordResetForm = ({hasValidationErrors, submitting}) => {
  const {t} = useTranslation()

  return (
    <FormSection title={t('PasswordResetForm.title')}>
      <TextInput
          name="email"
          label={t('PasswordResetForm.email')}
          placeholder="info@grason.cz"
          type="email"
          required
          showError={showErrorOnBlur}
      />
      <Button
          variant="block"
          disabled={hasValidationErrors}
          loading={submitting || undefined}
          type="submit"
      >
        {t('PasswordResetForm.submit')}
      </Button>
    </FormSection>
  )
}

PasswordResetForm.propTypes = {
  hasValidationErrors: PropTypes.bool,
  submitting: PropTypes.bool,
}

export default withForm({validate})(PasswordResetForm)
