import {useEffect, useState} from 'react'
import {useTheme} from 'styled-components'


const useMediaTier = (media) => {
  const theme = useTheme()
  const [mediaMatches, setMediaMatches] = useState(null)
  const handleChange = (mediaQuery) => setMediaMatches(mediaQuery.matches)
  useEffect(() => {
    if (typeof window === 'undefined') return
    const mediaQuery = window.matchMedia(`(max-width: ${theme.responsive.breakpoints[media]}px)`)
    setMediaMatches(mediaQuery.matches)
    mediaQuery.addEventListener('change', handleChange)
    return () => {
      mediaQuery.removeEventListener('change', handleChange)
    }
  }, [theme, media])
  return mediaMatches
}
export default useMediaTier
