import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import media from '../../frontend/utils/media'
import {px2rem} from '../theme/theme'
import Noise from '../assets/noise.png'
import Clock from '../assets/clock.png'
import LogoBlue from '../assets/logo-blue.svg'


const StyledLayout = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  ${media.up('mobile')(css`
  &::before {
    position: absolute;
    top: -22.5rem;
    left: -22.5rem;
    content: '';
    height: 45rem;
    width: 45rem;
    border-radius: 50%;
    background-color: rgba(0,0,0, 0.05);
    z-index: 1;
  }
    &::after {
      position: absolute;
      bottom: -35rem;
      left: 36%;
      content: '';
      height: 70rem;
      width: 70rem;
      border-radius: 50%;
      background-color: rgba(0,0,0, 0.05);
    }
  `)}
`

const StyledBrandContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  z-index: 1;
  justify-content: center;
`

const StyledBrand = styled.img`
  max-width: ${px2rem(100)};
  margin-right: ${({theme}) => theme.spacer};
`


const StyledImageContainer = styled.div`
  display: flex;
  justify-content: center;
`

const StyledImage = styled.img`
  display: block;
  margin: 4rem 0 3rem;
`

const StyledMain = styled.main`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({theme}) => theme.spacer};
  width: 100%;
  max-width: 100%;
  min-height: 100%;
  padding: 3.5rem 1rem;
  background-color: ${({theme}) => theme.colors.primary};
  background-image: url("${Noise}");
`

const StyledContent = styled.div`
  width: 100%;
`

const StyledFooter = styled.footer`
  position: relative;
  z-index: 1;
  margin-top: auto;
  width: 100%;
`

const ThankYouLayout = ({webLink, actions, children}) => {
  return (
    <StyledLayout>
      <StyledMain>
        <StyledContent>
          <StyledBrandContainer mobileVisible>
            <a href={webLink} target="_blank" rel="noreferrer noopener">
              <StyledBrand src={LogoBlue} alt="Grason" />
            </a>
          </StyledBrandContainer>
          <StyledImageContainer>
            <StyledImage src={Clock} alt="Clock" />
          </StyledImageContainer>
          {children}
        </StyledContent>
        <StyledFooter>
          {actions}
        </StyledFooter>
      </StyledMain>
    </StyledLayout>
  )
}

ThankYouLayout.propTypes = {
  webLink: PropTypes.string.isRequired,
  actions: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
}

export default ThankYouLayout
