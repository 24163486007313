import PropTypes from 'prop-types'
import {useTransition} from 'react'
import {useTranslation} from 'react-i18next'
import {SUSPENSE_CONFIG} from '../../../../constants/suspense'
import BaseButton from '../../../uikit/atoms/Button'


const Button = ({children, variant, color, disabled, loading, onClick, ...props}) => {
  const [isPending, startTransition] = useTransition(SUSPENSE_CONFIG)
  const usedLoading = isPending || loading
  const {t} = useTranslation()

  const handleClick = (e) => {
    return startTransition(() => {
      return onClick(e)
    })
  }

  return (
    <BaseButton
        disabled={disabled}
        loading={usedLoading && t('Button.loading')}
        onClick={onClick && handleClick}
        variant={variant}
        color={color}
        {...props}
    >
      {children}
    </BaseButton>
  )
}

Button.propTypes = {
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  variant: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default Button
