import {useState} from 'react'
import {If, For, Choose, When, Otherwise} from 'babel-plugin-jsx-control-statements'
import {useTranslation} from 'react-i18next'
import {Redirect, useNavigate} from '@reach/router'
import {FORM_ERROR} from 'final-form'
import * as routes from '../../../../../constants/routes'
import {
  RegistrationLayout, RegistrationPointsContainer, RegistrationPoint, RegistrationCheckmarkContainer,
  RegistrationLogosContainer, Autolayout, Stepper, Text, RegistrationActions,
} from '../../../../uikit'
import CervenyJelen from '../../../../uikit/assets/cerveny-jelen.svg'
import Zatisi from '../../../../uikit/assets/zatisi.svg'
import UMateje from '../../../../uikit/assets/u-mateje.svg'
import GreenCheckmark from '../../../../uikit/assets/greenCheckmark.svg'
import Scuk from '../../../../uikit/assets/scuk.png'
import DameJidlo from '../../../../uikit/assets/dame-jidlo.png'
import Datart from '../../../../uikit/assets/datart.png'
import useMediaTier from '../../../../uikit/useMediaTier'
import {useAuth, useSession} from '../../../hooks/auth'
import config from '../../../config'
import {useApiMutation, useConfig} from '../../../hooks/api'
import uuid from '../../../utils/uuid'
import {useQueryParam} from '../../../hooks/router'
import Button from '../../containers/Button'
import FormError from '../../form/FormError'
import Link from '../../containers/Link'
import {gtmEvent} from '../../containers/GoogleAnalytics'
import RegistrationContactDataForm from './RegistrationContactDataForm'
import RegistrationPersonalDetailsForm from './RegistrationPersonalDetailsForm'
import RegistrationCompanyDataForm from './RegistrationCompanyDataForm'


const logos = [
  {src: Datart, alt: 'logo Datart'},
  {src: Scuk, alt: 'logo Scuk'},
  {src: DameJidlo, alt: 'logo Dáme jídlo'},
  {src: Zatisi, alt: 'logo Zátiší'},
  {src: UMateje, alt: 'logo U Matěje'},
  {src: CervenyJelen, alt: 'logo Červený jelen'},
]

const Registration = () => {
  const {industries} = useConfig()
  const session = useSession()
  const {refreshAuth} = useAuth()
  const webview = useQueryParam('webview')
  const [values, setValues] = useState()
  const [id, setId] = useState()
  const [step, setStep] = useState(0)
  const {t} = useTranslation()
  const isMobile = useMediaTier('mobile')
  const navigate = useNavigate()

  const handleStepClick = (idx) => {
    setStep(idx)
  }

  const handleBack = () => {
    setStep(step - 1)
  }

  const createStep1 = useApiMutation({
    route: routes.API_REGISTER_STEP1,
    config: {useErrorBoundary: false},
  })

  const createStep2 = useApiMutation({
    route: routes.API_REGISTER_STEP2,
    config: {useErrorBoundary: false},
  })

  const createProfile = useApiMutation({
    route: routes.API_REGISTER,
    config: {useErrorBoundary: false},
  })

  const handleSubmit = async (data) => {
    try {
      if (step === 0) {
        const profileId = uuid()
        await createStep1.mutateAsync({
          uuid: profileId,
          email: data.email,
          phone: data.countryCode + data.phone,
        })
        gtmEvent({event: 'registrationForm', registrationStep: 'step2'})
        setId(profileId)
        setStep(1)
        setValues(data)
      } else if (step === 1) {
        await createStep2.mutateAsync({
          uuid: id,
          firstName: data.firstName,
          lastName: data.lastName,
        })
        gtmEvent({event: 'registrationForm', registrationStep: 'step3'})
        setStep(2)
        setValues(data)
      } else {
        setValues(data)
        const token = await createProfile.mutateAsync({
          uuid: id,
          email: data.email,
          password: data.password,
          firstName: data.firstName,
          lastName: data.lastName,
          countryCode: data.countryCode,
          phone: data.phone,
          business: data.business,
          cin: data.cin,
          city: data.city,
          street: data.street,
          zip: data.zip,
          industryId: data.industryId,
        })

        if (isMobile) {
          navigate(routes.REGISTRATION_SUCCESS, {state: {token: token.token, webview}})
        } else {
          gtmEvent({event: 'sign_up', finalScreen: 'desktop'})
          await refreshAuth(token.token)
        }
      }
    } catch (e) {
      return {[FORM_ERROR]: e.message}
    }
  }

  // Cannot redirect if on mobile because the app gets stuck when logged in
  if (session && isMobile === false) {
    return <Redirect to={routes.DASHBOARD} noThrow />
  }

  const points = [
    t('Registration.point1'),
    t('Registration.point2'),
    t('Registration.point3'),
  ]

  return (
    <RegistrationLayout
        webLink={config.web}
        title={<Text component="h1" textStyle="h1">{t('Registration.title')}</Text>}
        backLink={
          <Button
              href={config.web}
              variant="plain"
              size="default"
              component="a"
              color="text"
              iconName="arrow_back"
              target="_blank"
              rel="noreferrer noopener"
              gap
          >
            {t('Registration.backToWeb')}
          </Button>
        }
        topRightLink={
          <Link to={routes.LOGIN} color="text" size="default">{t('Registration.login')}</Link>
        }
    >
      <Choose>
        <When condition={step === 0}>
          <If condition={points}>
            <RegistrationPointsContainer>
              <For each="point" of={points} index="idx">
                <RegistrationPoint key={idx}>
                  <RegistrationCheckmarkContainer>
                    <img src={GreenCheckmark} />
                  </RegistrationCheckmarkContainer>
                  <span>{point}</span>
                </RegistrationPoint>
              </For>
            </RegistrationPointsContainer>
          </If>
          <If condition={logos}>
            <RegistrationLogosContainer>
              <For each="logo" of={logos} index="idx">
                <img src={logo.src} alt={logo.alt} key={idx} />
              </For>
            </RegistrationLogosContainer>
          </If>
          <RegistrationContactDataForm onSubmit={handleSubmit} initialValues={values}>
            {({form, submitError, handleSubmit, submitting}) => (
              <>
                <Autolayout column crossAxis="center">
                  <Autolayout column width="45%" responsive>
                    <FormError submitError={submitError} />
                    {form}
                    <Text align="center">
                      {t('Registration.consentText1')}
                      <Link
                          component="a"
                          href={config.termsUrl}
                          color="text"
                      >
                        {t('Registration.consentLink1')}
                      </Link><br />
                      {t('Registration.consentText2')}
                      <Link
                          component="a"
                          href={config.privacyPolicyUrl}
                          color="text"
                      >
                        {t('Registration.consentLink2')}
                      </Link>.
                    </Text>
                    <If condition={!isMobile}>
                      <Autolayout mainAxis="justify" height="6rem" crossAxis="end">
                        <Button
                            component="a"
                            variant="filled"
                            color="background1"
                            href={config.webForCompanies}
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                          {t('Registration.back')}
                        </Button>
                        <Button
                            variant="filled"
                            type="submit"
                            loading={submitting}
                            onClick={handleSubmit}
                            tabIndex={0}
                        >
                          {t('Registration.continue')}
                        </Button>
                      </Autolayout>
                    </If>
                  </Autolayout>
                </Autolayout>
                <If condition={isMobile}>
                  <RegistrationActions>
                    <Button
                        variant="filled"
                        type="submit"
                        loading={submitting}
                        onClick={handleSubmit}
                    >
                      {t('Registration.continue')}
                    </Button>
                  </RegistrationActions>
                </If>
              </>
            )}
          </RegistrationContactDataForm>
        </When>
        <When condition={step === 1}>
          <RegistrationPersonalDetailsForm onSubmit={handleSubmit} initialValues={values}>
            {({form, submitError, handleSubmit, submitting}) => (
              <>
                <Autolayout column crossAxis="center">
                  <Autolayout column width="50%" responsive>
                    <Stepper
                        active={step}
                        onClick={handleStepClick}
                        steps={[
                          `${t('Registration.step1')}`,
                          `${t('Registration.step2')}`,
                          `${t('Registration.step3')}`,
                        ]}
                    />
                    <FormError submitError={submitError} />
                    {form}
                    <If condition={!isMobile}>
                      <Autolayout mainAxis="justify" height="6rem" crossAxis="end">
                        <Button
                            variant="filled"
                            color="background1"
                            onClick={handleBack}
                        >
                          {t('Registration.back')}
                        </Button>
                        <Button
                            variant="filled"
                            loading={submitting}
                            onClick={handleSubmit}
                            tabIndex={0}
                        >
                          {t('Registration.continue')}
                        </Button>
                      </Autolayout>
                    </If>
                  </Autolayout>
                </Autolayout>
                <If condition={isMobile}>
                  <RegistrationActions>
                    <Button
                        variant="filled"
                        loading={submitting}
                        onClick={handleSubmit}
                    >
                      {t('Registration.continue')}
                    </Button>
                    <Button
                        variant="filled"
                        color="background1"
                        onClick={handleBack}
                    >
                      {t('Registration.back')}
                    </Button>
                  </RegistrationActions>
                </If>
              </>
            )}
          </RegistrationPersonalDetailsForm>
        </When>
        <Otherwise>
          <RegistrationCompanyDataForm onSubmit={handleSubmit} initialValues={values} industries={industries}>
            {({form, submitError, handleSubmit, submitting}) => (
              <>
                <Autolayout column crossAxis="center">
                  <Autolayout column width="50%" responsive>
                    <Stepper
                        active={step}
                        onClick={handleStepClick}
                        steps={[
                          `${t('Registration.step1')}`,
                          `${t('Registration.step2')}`,
                          `${t('Registration.step3')}`,
                        ]}
                    />
                    <FormError submitError={submitError} />
                    {form}
                    <If condition={!isMobile}>
                      <Autolayout mainAxis="justify" height="6rem" crossAxis="end">
                        <Button
                            variant="filled"
                            color="background1"
                            onClick={handleBack}
                        >
                          {t('Registration.back')}
                        </Button>
                        <Button
                            variant="filled"
                            loading={submitting}
                            onClick={handleSubmit}
                        >
                          {t('Registration.register')}
                        </Button>
                      </Autolayout>
                    </If>
                  </Autolayout>
                </Autolayout>
                <If condition={isMobile}>
                  <RegistrationActions>
                    <Button
                        variant="filled"
                        loading={submitting}
                        onClick={handleSubmit}
                    >
                      {t('Registration.register')}
                    </Button>
                    <Button
                        variant="filled"
                        color="background1"
                        onClick={handleBack}
                    >
                      {t('Registration.back')}
                    </Button>
                  </RegistrationActions>
                </If>
              </>
            )}
          </RegistrationCompanyDataForm>
        </Otherwise>
      </Choose>
    </RegistrationLayout>
  )
}

export default Registration
