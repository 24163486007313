import {useTranslation} from 'react-i18next'
import {useCallback} from 'react'
import {memoize} from 'lodash-es'
import * as patterns from '../../../../../constants/patterns'
import * as routes from '../../../../../constants/routes'
import {Autolayout, HiddenInput} from '../../../../uikit'
import mapValuesDeep from '../../../../lib/mapValuesDeep'
import {showErrorOnBlur, validatePatterns} from '../../../utils/forms'
import config from '../../../config'
import {useApiMutation} from '../../../hooks/api'
import withForm from '../../form/withForm'
import TextInput from '../../form/TextInput'
import SelectInput from '../../form/SelectInput'


const validate = (values, {t}) => {
  const errors = {}

  validatePatterns(errors, values, {
    email: patterns.EMAIL,
    countryCode: patterns.COUNTRY_CODE,
    phone: patterns.PHONE,
  })

  return mapValuesDeep(errors, (key) => t(key))
}

const useAsyncValidation = () => {
  const {t} = useTranslation()

  const verifyEmailMutation = useApiMutation({
    route: routes.API_REGISTER_VERIFY_EMAIL,
    config: {useErrorBoundary: false},
  })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isEmailInvalid = useCallback(memoize(async (email) => {
    try {
      await verifyEmailMutation.mutateAsync({email})
      return null
    } catch (e) {
      return e.message
    }
  }), [])
  const validateEmail = async (email, values, {pristine}) => {
    if (pristine) return
    const errors = validate(values, {t})
    if (errors.email) return errors.email

    return await isEmailInvalid(email)
  }

  const verifyPhoneMutation = useApiMutation({
    route: routes.API_REGISTER_VERIFY_PHONE,
    config: {useErrorBoundary: false},
  })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isPhoneInvalid = useCallback(
    memoize(async ({countryCode, phone}) => {
      try {
        await verifyPhoneMutation.mutateAsync({countryCode, phone})
        return null
      } catch (e) {
        return e.message
      }
    }, JSON.stringify),
    [],
  )
  const validatePhone = async (phone, values, {pristine}) => {
    if (pristine) return
    const errors = validate(values, {t})
    if (errors.phone) return errors.phone

    return await isPhoneInvalid({countryCode: values.countryCode, phone})
  }

  return {validatePhone, validateEmail}
}

const options = [
  {label: '+420', value: '+420'},
  {label: '+421', value: '+421'},
]

const RegistrationContactDataForm = () => {
  const {t} = useTranslation()
  const {validateEmail, validatePhone} = useAsyncValidation()

  return (
    <>
      <TextInput
          name="email"
          label={t('RegistrationContactDataForm.email')}
          placeholder={t('RegistrationContactDataForm.emailPlaceholder')}
          type="email"
          fieldProps={{validate: validateEmail, validateFields: []}}
          showError={showErrorOnBlur}
          required
          autoComplete="email"
      />
      <Autolayout>
        <Autolayout width="calc(4ch + 5rem)">
          <SelectInput
              name="countryCode"
              label={t('RegistrationContactDataForm.countryCode')}
              options={options}
              fieldProps={{
                initialValue: options[0].value,
                validateFields: ['phone', 'countryCode'],
              }}
              autoComplete="tel-country-code"
          />
        </Autolayout>
        <TextInput
            name="phone"
            type="tel"
            placeholder={config.phoneNumber}
            fieldProps={{validate: validatePhone, validateFields: ['phone', 'countryCode']}}
            label={t('RegistrationContactDataForm.phone')}
            autoComplete="tel-national"
            required
            maxLength={patterns.PHONE_LENGTH}
            showError={showErrorOnBlur}
        />
        <HiddenInput type="submit" />
      </Autolayout>
    </>
  )
}

export default withForm({validate})(RegistrationContactDataForm)
