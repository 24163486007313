import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {If} from 'babel-plugin-jsx-control-statements'
import media from '../../frontend/utils/media'
import errorBackground from '../assets/errorBackground.jpg'
import StoreBadge from '../atoms/StoreBadge'
import Autolayout from '../atoms/Autolayout'
import {px2rem} from '../theme/theme'
import LogoWhite from '../assets/logo-white.svg'


const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  min-height: 100%;
  background:
    linear-gradient(180deg, rgba(32, 31, 75, 0.6) 0%, rgba(13, 12, 34, 0.93) 100%),
    url("${({backroundImage}) => backroundImage}") no-repeat center center;
  background-size: cover;
`

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({theme}) => theme.spacer};
  height: 100%;
  max-width: ${px2rem(335)};
  padding: ${({theme}) => theme.spacer};
  ${media.down('mobile')(css`
    justify-content: space-between;
  `)}
`

const StyledPaper = styled.div`
  padding: ${({theme}) => theme.spacer};
  border-radius: ${({theme}) => theme.border.radius};
  background: ${({theme}) => theme.colors.background2};
  ${media.down('mobile')(css`
    margin-bottom: auto;
  `)}
`

const StyledBrand = styled.img`
  max-width: ${px2rem(100)};
  margin-bottom: ${({theme}) => theme.spacer};
`

const ErrorLayout = ({backroundImage, webLink, iosLink, playLink, language, children, button, ...props}) => {
  return (
    <StyledWrapper backroundImage={backroundImage ? backroundImage : errorBackground} {...props}>
      <StyledContainer>
        <StyledPaper>
          {children}
        </StyledPaper>
        <If condition={webLink}>
          <a href={webLink} target="_blank" rel="noreferrer noopener">
            <StyledBrand src={LogoWhite} alt="Grason" />
          </a>
        </If>
        <Autolayout crossAxis="center" mainAxis="justify">
          <If condition={iosLink}>
            <StoreBadge store="ios" language={language} link={iosLink} />
          </If>
          <If condition={playLink}>
            <StoreBadge store="play" language={language} link={playLink} />
          </If>
        </Autolayout>
        {button}
      </StyledContainer>
    </StyledWrapper>
  )
}

ErrorLayout.propTypes = {
  backroundImage: PropTypes.string,
  button: PropTypes.node,
  webLink: PropTypes.string,
  iosLink: PropTypes.string,
  playLink: PropTypes.string,
  language: PropTypes.string,
  children: PropTypes.node,
}

export default ErrorLayout
