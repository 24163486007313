import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'
import * as patterns from '../../../../../constants/patterns'
import mapValuesDeep from '../../../../lib/mapValuesDeep'
import {HiddenInput} from '../../../../uikit'
import {showErrorOnBlur, validatePatterns} from '../../../utils/forms'
import withForm from '../../form/withForm'
import TextInput from '../../form/TextInput'
import PasswordInput from '../../form/PasswordInput'


const validate = (values, {t}) => {
  const errors = {}

  validatePatterns(errors, values, {
    firstName: patterns.SOME,
    lastName: patterns.SOME,
    password: patterns.SOME,
  })

  return mapValuesDeep(errors, (key) => t(key))
}

const RegistrationPersonalDetailsForm = ({values}) => {
  const {t} = useTranslation()
  return (
    <>
      <TextInput
          name="firstName"
          label={t('RegistrationPersonalDetailsForm.name')}
          placeholder={t('RegistrationPersonalDetailsForm.namePlaceholder')}
          type="text"
          required
          showError={showErrorOnBlur}
          autoComplete="given-name"
      />
      <TextInput
          name="lastName"
          type="text"
          label={t('RegistrationPersonalDetailsForm.surname')}
          placeholder={t('RegistrationPersonalDetailsForm.surnamePlaceholder')}
          required
          autoComplete="family-name"
      />
      {/* Hint for autocomplete to at least save the correct email */}
      <HiddenInput value={values?.email} autoCompelte="email" name="email" />
      <PasswordInput
          key="password"
          name="password"
          label={t('RegistrationPersonalDetailsForm.password')}
          required
          autoComplete="new-password"
          id="password"
      />
      <HiddenInput type="submit" />
    </>
  )
}

RegistrationPersonalDetailsForm.propTypes = {
  values: PropTypes.object.isRequired,
}

export default withForm({validate})(RegistrationPersonalDetailsForm)
