import {Component, createContext, useContext, useEffect, useState, useCallback, useMemo} from 'react'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'
import * as routes from '../../../constants/routes'
import {api} from '../utils/api'
import * as storage from '../utils/storage'
import useIsMounted from './useIsMounted'
import {useQueryParam} from './router'


const AuthContext = createContext()
const SessionContext = createContext()
const STORAGE_KEY = 'session'
const ADMIN_FLAG_KEY = 'grasonAdmin'

export const isAdminSession = () => (
  Boolean(storage.get(window.localStorage, ADMIN_FLAG_KEY))
)

export const readSession = () => {
  const session = storage.get(window.localStorage, STORAGE_KEY)
  if (!session) return null

  return session
}

const removeSession = () => {
  storage.set(window.localStorage, STORAGE_KEY, null)
  storage.set(window.localStorage, ADMIN_FLAG_KEY, null)
}

const writeSession = (session) => {
  if (!session) return removeSession()

  storage.set(window.localStorage, STORAGE_KEY, session)
}

/**
 * This error boundary will catch errors and if logged out error is encountered,
 * will call onError function passed as prop.
 */
class ErrorBoundary extends Component {
  static getDerivedStateFromError(error) {
    return {
      hasError: error?.status !== 401,
    }
  }

  static propTypes = {
    onError: PropTypes.func.isRequired,
    children: PropTypes.node,
  }

  componentDidCatch(error, _errorInfo) {
    if (error?.status === 401) {
      this.props.onError()
      return
    }
    throw error
  }

  render() {
    if (this.state?.hasError) return null
    return this.props.children
  }
}

export const AuthProvider = ({children, loading}) => {
  const {t, i18n} = useTranslation()
  const [isInitialized, setInitialized] = useState(false)
  const [session, setSession] = useState(null)
  const isMounted = useIsMounted()
  const urlToken = useQueryParam('token')
  const language = i18n.resolvedLanguage

  // Store admin sessions flag for X-Grason-Admin header to be sent
  const asAdmin = Boolean(useQueryParam('grasonAdmin'))
  useEffect(() => {
    if (!asAdmin) return
    storage.set(window.localStorage, ADMIN_FLAG_KEY, true)
  }, [asAdmin])

  const handleError = useCallback(() => {
    writeSession(null)
    if (isMounted.current) setSession(null)
    if (isMounted.current) setInitialized(true)
  }, [isMounted])

  const refreshAuth = useCallback(async (token) => {
    if (isMounted.current) setInitialized(false)
    let newSession = readSession()

    if (token || urlToken) newSession = {token: token || urlToken}

    if (newSession) {
      try {
        const res = await api('GET', routes.API_ME, {sessionToken: newSession.token, language, asAdmin})
        if (!res.manager) return handleError()

        newSession = {
          ...newSession,
          manager: res.manager,
        }

      } catch (e) {
        return handleError()
      }
    }

    writeSession(newSession)
    if (isMounted.current) setSession(newSession)
    if (isMounted.current) setInitialized(true)
  }, [urlToken, asAdmin, isMounted, handleError, language])

  const login = useCallback(async ({email, password}) => {
    let newSession
    try {
      const data = {
        login: email,
        password,
      }
      newSession = await api('POST', routes.API_LOGIN, {data, language})
      if (!newSession.manager) throw new Error(t('AuthProvider.managersOnlyError'))

      writeSession(newSession)
      if (isMounted.current) setSession(newSession)
      return newSession
    } catch (e) {
      newSession = null
      writeSession(newSession)
      if (isMounted.current) setSession(newSession)
      return e
    }
  }, [language, t, isMounted])

  const logout = useCallback(async () => {
    writeSession(null)
    if (isMounted.current) setSession(null)
  }, [isMounted])

  useEffect(() => {
    refreshAuth()
  }, [refreshAuth])

  const utils = useMemo(() => ({
    refreshAuth,
    login,
    logout,
  }), [refreshAuth, login, logout])

  if (!isInitialized) return loading

  return (
    <AuthContext.Provider value={utils}>
      <SessionContext.Provider value={session}>
        <ErrorBoundary onError={handleError}>
          {children}
        </ErrorBoundary>
      </SessionContext.Provider>
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.node.isRequired,
}

export const useSession = () => {
  const session = useContext(SessionContext)
  return session
}

export const useAuth = () => {
  const {refreshAuth, login, logout} = useContext(AuthContext)
  return {
    refreshAuth,
    login,
    logout,
  }
}
