import PropTypes from 'prop-types'
import styled, {keyframes} from 'styled-components'
import {keys} from 'lodash-es'
import theme, {px2rem} from '../theme/theme'


const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`

const StyledSpinner = styled.div`
  display: inline-block;
  position: relative;
  width: ${({size, border}) => px2rem(size + 2 * border)};
  height: ${({size, border}) => px2rem(size + 2 * border)};

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${({size}) => px2rem(size)};
    height: ${({size}) => px2rem(size)};
    margin: ${({border}) => px2rem(border)};
    border: ${({border}) => px2rem(border)} solid;
    border-color: ${({theme, color}) => theme.colors[color]} transparent transparent transparent;
    border-radius: 50%;
    animation: ${rotate} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  }

  div:nth-child(1) {
    animation-delay: -0.45s;
  }

  div:nth-child(2) {
    animation-delay: -0.3s;
  }

  div:nth-child(3) {
    animation-delay: -0.15s;
  }
`

const Spinner = ({size, border, color, label}) => (
  <StyledSpinner title={label} size={size} border={border} color={color}>
    <div />
    <div />
    <div />
  </StyledSpinner>
)

Spinner.defaultProps = {
  label: 'Loading...',
  size: 64,
  border: 8,
  color: 'background2',
}
Spinner.propTypes = {
  label: PropTypes.string,
  size: PropTypes.number,
  border: PropTypes.number,
  color: PropTypes.oneOf(keys(theme.colors)),
}


export default Spinner
