import {isNumber} from 'lodash-es'
import {css} from 'styled-components'
import theme from '../../uikit/theme/theme'


const chooseBreakpoint = (breakpoint) => {
  return isNumber(breakpoint) ? breakpoint : theme.responsive.breakpoints[breakpoint]
}

const media = ({
  down: (breakpoint) => (styles) => css`
    @media screen and (max-width: ${chooseBreakpoint(breakpoint)}px) {
      ${styles}
    }
  `,
  up: (breakpoint) => (styles) => css`
    @media screen and (min-width: ${chooseBreakpoint(breakpoint)}px) {
      ${styles}
    }
  `,
  between: (startPoint, endPoint) => (styles) => css`
    @media screen
      and (min-width: ${chooseBreakpoint(startPoint)}px)
      and (max-width: ${chooseBreakpoint(endPoint)}px) {
      ${styles}
    }
  `,
})

export default media
