import styled from 'styled-components'


const StyledInput = styled.input`
  position: absolute;
  left: -9999px;
  width: 1px;
  height: 1px;
`

const HiddenInput = ({...props}) => (
  <StyledInput {...props} tabIndex="-1" />
)

export default HiddenInput
