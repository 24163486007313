import {useTranslation} from 'react-i18next'
import {useEffect} from 'react'
import {useLocation} from '@reach/router'
import {If} from 'babel-plugin-jsx-control-statements'
import {isNil} from 'lodash-es'
import * as routes from '../../../../../constants/routes'
import {ThankYouLayout, Autolayout, Text} from '../../../../uikit'
import config from '../../../config'
import Button from '../../containers/Button'
import {gtmEvent} from '../../containers/GoogleAnalytics'


const RegistrationSuccess = () => {
  const {t} = useTranslation()
  const location = useLocation()
  const isWebview = !isNil(location.state?.webview)

  useEffect(() => {
    gtmEvent({event: 'sign_up', finalScreen: isWebview ? 'mobile' : 'appDownload'})
  }, [isWebview])

  return (
    <ThankYouLayout
        webLink={config.web}
        backgroundTitle={<h1>{t('RegistrationSuccess.title1')}<br /> {t('RegistrationSuccess.title2')}</h1>}
        actions={
          <Button
              component="a"
              size="block"
              color="text"
              {...(
                isWebview
                  ? {
                    href: `${routes.LOGIN}?token=${location.state?.token}`,
                  }
                  : {
                    target: '_blank',
                    rel: 'noreferrer noopener',
                    href: config.downloadUrl,
                  }
              )}
          >
            {t('RegistrationSuccess.download', {context: isWebview ? 'webview' : undefined})}
          </Button>
        }
    >
      <Autolayout column>
        <Text component="h1" textStyle="h1" align="center">
          {t('RegistrationSuccess.text1')}<br /> {t('RegistrationSuccess.text2')}
        </Text>
        <Text align="center">
          {t('RegistrationSuccess.text3', {context: isWebview ? 'webview' : undefined})}&nbsp;
          <If condition={!isWebview}>
            <a href={config.app} target="_blank" rel="noreferrer noopener">
              {t('RegistrationSuccess.link')}
            </a>.
          </If>
        </Text>
      </Autolayout>
    </ThankYouLayout>
  )
}

export default RegistrationSuccess
