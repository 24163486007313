/* global config */
/* global BUILD_ID */
// Config values provided by Webpack DefinePlugin

export default {
  version: BUILD_ID,
  environment: config.environment,
  apiUrl: config.api.url,
  sentry: config.sentryDsn,
  userAgent: `Grason/${BUILD_ID}`,
  web: 'https://grason.cz/',
  webForCompanies: 'https://grason.cz/pro-firmy',
  app: 'https://app.grason.cz/',
  appStore: 'https://www.grason.cz/m?p=ios',
  playStore: 'https://www.grason.cz/m?p=android',
  downloadUrl: 'https://www.grason.cz/stahnout/',
  privacyPolicyUrl: 'https://www.grason.cz/pravidla-ochrany-osobnich-udaju/',
  termsUrl: 'https://www.grason.cz/obchodni-podminky/',
  subscriptionNotice: config.app.subscription.notice,
  jobRatingDuration: config.app.job.ratingDuration,
  googleMapsKey: config.app.googleMapsKey,
  googleAnalytics: config.googleAnalytics,
  lowRatingThreshold: 3,
  phoneNumber: '739877799',
  email: 'info@grason.cz',
  automaticApproval: false,
  notificationsRefetchInterval: config.app.notificationsRefetchInterval,
  apiFileMaxSize: config.api.maxFileSize,
  enableAnalytics: config.app.analytics,
  onesignal: config.onesignalApplicationId,
}
