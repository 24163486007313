import {useTranslation} from 'react-i18next'
import PropTypes from 'prop-types'
import * as patterns from '../../../../../constants/patterns'
import * as routes from '../../../../../constants/routes'
import mapValuesDeep from '../../../../lib/mapValuesDeep'
import {FormSection} from '../../../../uikit'
import {showErrorOnBlur, validatePatterns} from '../../../utils/forms'
import TextInput from '../../form/TextInput'
import withForm from '../../form/withForm'
import Button from '../../containers/Button'
import PasswordInput from '../../form/PasswordInput'
import Link from '../../containers/Link'


const validate = (values, {t}) => {
  const errors = {}

  validatePatterns(errors, values, {
    email: [patterns.EMAIL, 'validations.email'],
    password: patterns.SOME,
  })

  return mapValuesDeep(errors, (key) => t(key))
}

const LoginForm = ({hasValidationErrors, submitting, onForgotClick}) => {
  const {t} = useTranslation()

  return (
    <FormSection title={t('LoginForm.title')}>
      <TextInput
          name="email"
          label={t('LoginForm.email')}
          placeholder="info@grason.cz"
          type="email"
          required
          showError={showErrorOnBlur}
      />
      <PasswordInput
          key="password"
          name="password"
          label={t('LoginForm.password')}
          required
      />
      <Button
          variant="block"
          disabled={hasValidationErrors}
          loading={submitting || undefined}
          type="submit"
      >
        {t('LoginForm.login')}
      </Button>
      <Link
          color="background1"
          variant="filled"
          size="default"
          to={routes.REGISTRATION}
      >
        {t('LoginForm.register')}
      </Link>
      <Button
          color="background1"
          variant="block"
          onClick={onForgotClick}
      >
        {t('LoginForm.forgot')}
      </Button>
    </FormSection>
  )
}

LoginForm.propTypes = {
  hasValidationErrors: PropTypes.bool,
  submitting: PropTypes.bool,
  onForgotClick: PropTypes.func.isRequired,
}

export default withForm({validate})(LoginForm)
