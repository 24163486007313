import PropTypes from 'prop-types'
import styled from 'styled-components'
import {px2rem} from '../theme/theme'
import AppstoreCs from '../assets/appstore-cs.svg'
import AppstoreEn from '../assets/appstore-en.svg'
import AppstoreSk from '../assets/appstore-sk.svg'
import PlaystoreCs from '../assets/playstore-cs.svg'
import PlaystoreEn from '../assets/playstore-en.svg'
import PlaystoreSk from '../assets/playstore-sk.svg'


const stores = {
  cs: {
    ios: {
      image: AppstoreCs,
      alt: 'Stáhnout v App Store',
    },
    play: {
      image: PlaystoreCs,
      alt: 'Nyní na Google Play',
    },
  },
  en: {
    ios: {
      image: AppstoreEn,
      alt: 'Download on the App Store',
    },
    play: {
      image: PlaystoreEn,
      alt: 'Get it on Google Play',
    },
  },
  sk: {
    ios: {
      image: AppstoreSk,
      alt: 'Stiahnuť na App Store',
    },
    play: {
      image: PlaystoreSk,
      alt: 'Teraz na Google Play',
    },
  },
}

const StyledImage = styled.img`
  min-height: 40px; /* Per design manual */
  height: ${px2rem(40)};
  width: 100%;
`

const StoreBadge = ({store, language, link}) => {
  const {image, alt} = stores[language][store]
  return (
    <a href={link} target="_blank" rel="noreferrer noopener">
      <StyledImage src={image} alt={alt} />
    </a>
  )
}

StoreBadge.propTypes = {
  language: PropTypes.oneOf(['cs', 'sk', 'en']).isRequired,
  store: PropTypes.oneOf(['ios', 'play']).isRequired,
  link: PropTypes.string,
}

export default StoreBadge
