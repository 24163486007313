import {useTranslation} from 'react-i18next'
import {toNumber} from 'lodash'
import PropTypes from 'prop-types'
import {useCallback, useState} from 'react'
import {memoize} from 'lodash-es'
import * as patterns from '../../../../../constants/patterns'
import * as routes from '../../../../../constants/routes'
import {Autolayout, HiddenInput} from '../../../../uikit'
import mapValuesDeep from '../../../../lib/mapValuesDeep'
import {useApiMutation} from '../../../hooks/api'
import {showErrorOnBlur, validatePatterns} from '../../../utils/forms'
import withForm from '../../form/withForm'
import TextInput from '../../form/TextInput'
import SelectInput from '../../form/SelectInput'


const validate = (values, {t}) => {
  const errors = {}

  validatePatterns(errors, values, {
    business: patterns.SOME,
    cin: patterns.CIN,
    industryId: patterns.INTEGER,
    street: patterns.SOME,
    city: patterns.SOME,
    zip: patterns.ZIP,
  })

  return mapValuesDeep(errors, (key) => t(key))
}

const parse = (values) => {
  return {
    ...values,
    industryId: toNumber(values.industryId),
  }
}

const RegistrationCompanyDataForm = ({industries}) => {
  const {t} = useTranslation()
  const options = industries
    .map(({id, name}) => ({label: name, value: id}))

  const [cinResult, setCinResult] = useState()

  const verifyCinMutation = useApiMutation({
    route: routes.API_ARES,
    config: {useErrorBoundary: false},
  })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isCinInvalid = useCallback(memoize(async (ico) => {
    try {
      return await verifyCinMutation.mutateAsync({ico})
    } catch (e) {
      return t('RegistrationCompanyDataForm.cinNotFound')
    }
  }), [])

  const validateCin = async (ico, values, {pristine}) => {
    if (pristine) return undefined
    const errors = validate(values, {t})
    if (errors.cin) return errors.cin
    setCinResult(undefined)
    const cinData = await isCinInvalid(ico)
    if (!cinData.company) {
      return cinData
    }

    setCinResult(cinData.company)
    return undefined
  }

  return (
    <>
      <TextInput
          name="business"
          label={t('RegistrationCompanyDataForm.businessName')}
          placeholder={t('RegistrationCompanyDataForm.businessNamePlaceholder')}
          type="text"
          required
          autoComplete="organization"
      />
      <TextInput
          name="cin"
          label={t('RegistrationCompanyDataForm.cin')}
          placeholder={t('RegistrationCompanyDataForm.cinPlaceholder')}
          helperText={cinResult}
          fieldProps={{validate: validateCin}}
          showError={showErrorOnBlur}
          required
          inputMode="numeric"
          autoComplete="off"
          maxLength={patterns.CIN_LENGTH}
      />
      <SelectInput
          name="industryId"
          label={t('RegistrationCompanyDataForm.industry')}
          placeholder={t('RegistrationCompanyDataForm.industryPlaceholder')}
          required
          options={options}
      />
      <TextInput
          name="street"
          label={t('RegistrationCompanyDataForm.street')}
          placeholder={t('RegistrationCompanyDataForm.streetPlaceholder')}
          type="text"
          required
          autoComplete="street-address"
      />
      <Autolayout>
        <TextInput
            name="city"
            label={t('RegistrationCompanyDataForm.city')}
            placeholder={t('RegistrationCompanyDataForm.cityPlaceholder')}
            type="text"
            required
            autoComplete="address-level2"
        />
        <Autolayout width="20ch">
          <TextInput
              name="zip"
              label={t('RegistrationCompanyDataForm.zip')}
              placeholder={t('RegistrationCompanyDataForm.zipPlaceholder')}
              type="text"
              inputMode="numeric"
              required
              autoComplete="postal-code"
              maxLength={patterns.ZIP_LENGTH}
          />
        </Autolayout>
      </Autolayout>
      <HiddenInput type="submit" />
    </>
  )
}

RegistrationCompanyDataForm.propTypes = {
  industries: PropTypes.array.isRequired,
}

export default withForm({validate, parse})(RegistrationCompanyDataForm)
