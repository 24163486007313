import {lazy, Suspense, useEffect} from 'react'
import {I18nextProvider} from 'react-i18next'
import {createHistory, LocationProvider} from '@reach/router'
import * as routes from '../../constants/routes'
import {ThemeProvider} from '../uikit'
import i18n, {fallbackLng} from './i18n'
import {AuthProvider} from './hooks/auth'
import {ApiProvider} from './hooks/api'
import Registration from './components/screens/registration/Registration'
import RegistrationSuccess from './components/screens/registration/RegistrationSuccess'
import Loading from './components/screens/Loading'
import ErrorFallback from './components/screens/ErrorFallback'
import Sentry from './sentry'
import ReachRouter from './components/containers/ReachRouter'
import GoogleAnalytics from './components/containers/GoogleAnalytics'
import Login from './components/screens/login/Login'
import oneSignal from './onesignal'


const history = createHistory(window)
const Router = lazy(() => import(/* webpackChunkName: "grason" */ './Router'))

const Screens = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Router />
    </Suspense>
  )
}

const App = () => {
  useEffect(() => {
    oneSignal()
  })

  return (
    <ThemeProvider>
      <Sentry.ErrorBoundary
          fallback={({resetError}) => <ErrorFallback resetError={resetError} />}
          showDialog
          dialogOptions={{lang: fallbackLng}}
      >
        <GoogleAnalytics history={history}>
          <Suspense fallback={<Loading />}>
            <I18nextProvider i18n={i18n}>
              <AuthProvider loading={<Loading />}>
                <ApiProvider>
                  <LocationProvider history={history}>
                    <ReachRouter>
                      <Screens default />
                      <RegistrationSuccess path={routes.REGISTRATION_SUCCESS} />
                      <Registration path={routes.REGISTRATION} />
                      <Login path={routes.LOGIN} />
                    </ReachRouter>
                  </LocationProvider>
                </ApiProvider>
              </AuthProvider>
            </I18nextProvider>
          </Suspense>
        </GoogleAnalytics>
      </Sentry.ErrorBoundary>
    </ThemeProvider>
  )
}

export default App
