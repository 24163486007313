import {rgba} from 'polished'


const GRASON_BLUE = '#12B8DC'
const GRASON_BLUE_LIGHT = 'rgba(18, 184, 220, 0.1)' // '#EAF8FA'
const DARK_BLUE = '#201F4B'
const GRAY = '#F0F0F3'
const GRAY_1 = '#C4C3CC'
const GRAY_2 = '#636379'
const WHITE = '#fff'

const GREEN = '#008122'
const GREEN_2 = '#DFF6E5'
const AMBER = '#FF8B41'
const AMBER_2 = '#F9E4DA'
const RED = '#DA3B3B'
const RED_2 = '#F9DDDD'

const YELLOW = '#FFCD06'
const YELLOW_2 = 'rgba(255, 205, 6, 0.2)'
const GOLD = '#FDB605'
const YELLOW_ORANGE = '#FB9504'
const ORANGE = '#F97904'
const DARK_ORANGE = '#F54902'
const ELECTRIC_RED = '#F00801'

// Fonts
const FONT_SIZE_BASE = 13
const FONT_SIZE_MOBILE = 13

export const px2rem = (size, base = FONT_SIZE_BASE) => `${size / base}rem`
const px2em = (size, base = FONT_SIZE_BASE) => `${size / base}em`


const theme = {
  spacer: px2rem(12),
  colors: {
    primary: GRASON_BLUE,
    primary2: GRASON_BLUE_LIGHT,
    background1: GRAY,
    background2: WHITE,
    text: DARK_BLUE,
    textInverted: WHITE,
    textSecondary: GRAY_2,
    textMuted: GRAY_1,
    positive: GREEN,
    positive2: GREEN_2,
    warning: AMBER,
    warning2: AMBER_2,
    negative: RED,
    negative2: RED_2,
    auxiliary: YELLOW,
    auxiliary2: YELLOW_2,
  },
  textColor: {
    primary: 'textInverted',
    primary2: 'primary',
    background1: 'text',
    background2: 'text',
    text: 'textInverted',
    textInverted: 'text',
    textSecondary: 'textInverted',
    textMuted: 'text',
    positive: 'textInverted',
    positive2: 'positive',
    warning: 'textInverted',
    warning2: 'warning',
    negative: 'textInverted',
    negative2: 'negative',
    auxiliary: 'text',
    auxiliary2: 'text',
  },
  pillColor: {
    primary: 'textInverted',
    primary2: 'text',
    background1: 'textSecondary',
    background2: 'text',
    text: 'textInverted',
    textInverted: 'text',
    textSecondary: 'textInverted',
    textMuted: 'text',
    positive: 'textInverted',
    positive2: 'positive',
    warning: 'textInverted',
    warning2: 'warning',
    negative: 'textInverted',
    negative2: 'negative',
    auxiliary: 'text',
    auxiliary2: 'text',
  },
  rateColor: {
    rating5: YELLOW,
    rating4: GOLD,
    rating3: YELLOW_ORANGE,
    rating2: ORANGE,
    rating1: DARK_ORANGE,
    rating: ELECTRIC_RED,
  },
  overlays: {
    primary: rgba(GRASON_BLUE, 0.1),
    backdrop: 'rgba(0, 0, 0, 0.5)',
    button: 0.2,
    modal: 'rgba(0, 0, 0, 0.16)',
  },
  fonts: {
    family: {
      text: 'Roboto',
      heading: 'Roboto',
      monospace: 'monospace',
    },
    size: {
      small: px2rem(11),
      normal: px2rem(FONT_SIZE_BASE),
      large: px2rem(16),
      extraLarge: px2rem(20),
      huge: px2rem(57),
    },
    weight: {
      regular: '400',
      semibold: '500',
      bold: '700',
    },
    lineHeight: {
      small: px2rem(16), // Parity with sizes
      normal: px2rem(16),
      large: px2rem(20),
      extraLarge: px2rem(48),
      extraExtraLarge: px2rem(35),
      huge: px2rem(62),
    },
    letterSpacing: {
      base: 'unset',
      wide: px2em(0.14),
      wider: px2em(0.4),
    },
  },
  responsive: {
    breakpoints: {
      mobile: 850,
      desktop: 1250,
    },
    zoom: {
      minimalViewport: 359,
      maximalViewport: 1920,
      designedViewport: 1920,
      fontSizeBase: FONT_SIZE_BASE,
      fontSizeMobile: FONT_SIZE_MOBILE,
    },
    container: {
      desktop: '100rem',
      mobile: px2rem(720, FONT_SIZE_MOBILE),
    },
  },
  animation: '.2s ease-in',
  border: {
    radius: px2rem(8),
    color: GRAY,
    size: '1px',
  },
  shadow: {
    button: `0 ${px2rem(2)} ${px2rem(8)}`,
    modal: `0 ${px2rem(8)} ${px2rem(24)}`,
  },
}

export default theme
