import {useState} from 'react'
import {isError} from 'lodash'
import {If, Choose, When, Otherwise} from 'babel-plugin-jsx-control-statements'
import {useTranslation} from 'react-i18next'
import {FORM_ERROR} from 'final-form'
import {Redirect} from '@reach/router'
import * as routes from '../../../../../constants/routes'
import {FormSection, LoginLayout} from '../../../../uikit'
import useMediaTier from '../../../../uikit/useMediaTier'
import {useAuth, useSession} from '../../../hooks/auth'
import config from '../../../config'
import {useApiMutation} from '../../../hooks/api'
import Button from '../../containers/Button'
import FormError from '../../form/FormError'
import MobileBlock from '../MobileBlock'
import LoginForm from './LoginForm'
import PasswordResetForm from './PasswordResetForm'


const Login = () => {
  const {login} = useAuth()
  const session = useSession()
  const isMobile = useMediaTier('mobile')
  const {t, i18n} = useTranslation()
  const [forgot, setForgot] = useState(false)
  const [reset, setReset] = useState(false)
  const passwordReset = useApiMutation({
    route: routes.API_FORGOT_PASSWORD,
    config: {useErrorBoundary: false},
  })

  const handleBackToLogin = () => {
    setForgot(false)
    setReset(false)
  }

  const handleLogin = async (values) => {
    const {email, password} = values
    const res = await login({email, password})

    if (isError(res)) {
      return {[FORM_ERROR]: res.message}
    }
  }

  const handlePasswordReset = async (values) => {
    try {
      const {email} = values
      await passwordReset.mutateAsync({email})
      setReset(true)
    } catch (e) {
      return {[FORM_ERROR]: e.message}
    }
  }

  if (isMobile) return <MobileBlock />
  if (session && isMobile !== null) return <Redirect to={routes.DASHBOARD} noThrow />

  return (
    <LoginLayout
        language={i18n.resolvedLanguage}
        webLink={config.web}
        iosLink={config.appStore}
        playLink={config.playStore}
    >
      <Choose>
        <When condition={forgot}>
          <div>
            <Button
                color="background1"
                onClick={handleBackToLogin}
                iconName="arrow_back"
            >
              {t('Login.backToLogin')}
            </Button>
          </div>
          <If condition={reset}>
            <FormSection title={t('Login.resetSuccessTitle')} color="positive">
              {t('Login.resetSuccess')}
            </FormSection>
          </If>
          <PasswordResetForm onSubmit={handlePasswordReset}>
            {({form, submitError}) => (
              <>
                <FormError submitError={submitError} />
                {form}
              </>
            )}
          </PasswordResetForm>
        </When>
        <Otherwise>
          <LoginForm onSubmit={handleLogin} onForgotClick={() => setForgot(true)}>
            {({form, submitError}) => (
              <>
                <FormError submitError={submitError} />
                {form}
              </>
            )}
          </LoginForm>
        </Otherwise>
      </Choose>
    </LoginLayout>
  )
}

export default Login
