import {useTranslation} from 'react-i18next'
import {ErrorLayout, Text} from '../../../uikit'
import config from '../../config'
import Button from '../containers/Button'


const MobileBlock = () => {
  const {t, i18n} = useTranslation()
  return (
    <ErrorLayout
        language={i18n.resolvedLanguage}
        webLink={config.web}
        iosLink={config.appStore}
        playLink={config.playStore}
        button={(
          <Button
              href={config.web}
              variant="filled"
              size="block"
              component="a"
          >
            {t('MobileBlock.button')}
          </Button>
        )}
    >
      <h1>{t('MobileBlock.title')}</h1>
      <Text component="p">{t('MobileBlock.text')}</Text>
    </ErrorLayout>
  )
}

export default MobileBlock
