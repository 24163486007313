import PropTypes from 'prop-types'
import {ThemeProvider as StyledThemeProvider} from 'styled-components'
import GlobalStyles from './GlobalStyles'
import theme from './theme'


const ThemeProvider = ({children, ...props}) => {
  return (
    <StyledThemeProvider theme={theme} {...props}>
      <GlobalStyles />
      {children}
    </StyledThemeProvider>
  )
}

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ThemeProvider
