import PropTypes from 'prop-types'
import {Link as RouterLink} from '@reach/router'
import Button from '../../../uikit/atoms/Button'


const Link = ({children, to, ...props}) => {
  return (
    <Button variant="plain" color="primary" size="short" component={RouterLink} to={to} {...props}>
      {children}
    </Button>
  )
}

Link.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default Link
