import PropTypes from 'prop-types'
import {useState} from 'react'
import {find} from 'lodash'
import FormSection from './FormSection'
import FormComponent from './FormComponent'


export const fieldPropTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  invalid: PropTypes.bool,
  required: PropTypes.bool,
}

export const fieldArgTypes = {
  name: {control: 'text'},
  value: {control: 'text'},
  onChange: {control: 'func'},
  onFocus: {control: 'func'},
  onBlur: {control: 'func'},
  disabled: {control: 'boolean'},
  invalid: {control: 'boolean'},
  required: {control: 'boolean'},
}

export const buildFormStory = (Component) => {
  const FormIntegration = ({title, label, helperText, onChange, onSubmit, options, ...args}) => {
    const [value, setValue] = useState('')
    const handleChange = (e) => {
      if (e.target) {
        setValue(e.target.type === 'checkbox' ? e.target.checked : e.target.value)
      } else {
        setValue(e)
      }
      return onChange(e)
    }
    const handleSubmit = (e) => {
      onSubmit(value, e)
      e.preventDefault()
    }
    const selectedOption = find(options, {value})

    return (
      <form onSubmit={handleSubmit}>
        <FormSection title={title}>
          <FormComponent
              invalid={args.invalid}
              required={args.required}
              label={label}
              helperText={helperText || selectedOption?.description}
          >
            <Component value={value} label={label} options={options} {...args} onChange={handleChange} />
          </FormComponent>
        </FormSection>
      </form>
    )
  }

  FormIntegration.propTypes = {
    title: PropTypes.string,
    label: PropTypes.string.isRequired,
    helperText: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    options: PropTypes.array,
  }

  FormIntegration.argTypes = {
    title: {control: 'text'},
    label: {control: 'text'},
    helperText: {control: 'text'},
    onSubmit: {control: 'func'},
  }

  FormIntegration.args = {
    title: 'Form section',
    label: 'Some field',
  }
  return FormIntegration
}
