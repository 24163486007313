import PropTypes from 'prop-types'
import {If, For} from 'babel-plugin-jsx-control-statements'
import styled, {css} from 'styled-components'
import {isFunction} from 'lodash'
import media from '../../frontend/utils/media'
import Step from './Step'


const StyledWrapper = styled.div`
  position: relative;
  padding-bottom: ${({theme}) => `calc(${theme.spacer} * 5) `};
  ${media.down('mobile')(css`
    padding-bottom: ${({theme}) => `calc(${theme.spacer} * 2) `};
  `)}
`

const StyledStepperContainer = styled.div`
  display: flex;
  padding: ${({theme}) => theme.spacer} 0;
`

const StyledStepBar = styled.div`
  margin-top: ${({theme}) => `calc(${theme.spacer} * 2.275) `};
  border-top:
    ${({theme, active}) => active
    ? `4px solid  ${theme.colors.primary}`
    : `4px solid ${theme.colors.background1}`};
  width: 100%;
  ${media.down('mobile')(css`
    margin-top: ${({theme}) => `calc(${theme.spacer} * 1.5) `};
  `)}
`

const Stepper = ({active, steps, onClick, ...props}) => {
  const handleClick = (e, idx) => {
    e.preventDefault()
    if (isFunction(onClick) && active > idx) {
      return onClick(idx)
    }
    return idx
  }

  const isClickable = (idx) => {
    return active > idx
  }

  return (
    <StyledWrapper>
      <StyledStepperContainer {...props}>
        <For each="step" of={steps} index="idx">
          <If condition={idx > 0}>
            <StyledStepBar active={idx < active + 1} />
          </If>
          <Step
              key={step}
              number={idx + 1}
              active={idx < active + 1}
              checked={idx < active}
              onClick={(e) => handleClick(e, idx)}
              clickable={isClickable(idx)}
              moreSteps={steps.length > 3}
          >
            {step}
          </Step>
          <If condition={idx !== steps.length - 1}>
            <StyledStepBar active={idx < active + 1} />
          </If>
        </For>
      </StyledStepperContainer>
    </StyledWrapper>
  )
}

Stepper.propTypes = {
  active: PropTypes.number,
  steps: PropTypes.array.isRequired,
  onClick: PropTypes.func,
}

export default Stepper
