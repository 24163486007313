import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {Choose, When, Otherwise} from 'babel-plugin-jsx-control-statements'
import media from '../../frontend/utils/media'
import {px2rem} from '../theme/theme'
import * as typography from '../typography'
import Checkmark from '../assets/checkmark.svg'


const StyledStepContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  z-index: 1;

  &:first-child {
    span:nth-child(2) {
      left: 0;
    }
  }

  &:last-child {
    span:nth-child(2) {
      right: 0;
    }
  }
`

const StyledStep = styled.div`
  display: flex;
  border: 4px solid ${({theme, active}) => active ? theme.colors.primary : theme.colors.background1};
  border-radius: 50%;
  background-color: ${({theme, checked}) => checked ? theme.colors.primary : 'transparent'};
  justify-content: center;
  align-items: center;
  padding: 0;
  min-width: ${px2rem(60)};
  color: ${({theme, active}) => active ? theme.colors.primary : theme.colors.text};
  ${({clickable}) => clickable && css`
    cursor: pointer;
  `}
  ${media.down('mobile')(css`
    min-width: ${px2rem(42)};
  `)}

  &::before {
    display: inline-block;
    padding-top: 100%;
    vertical-align: middle;
    content: '';
  }
`

const StyledNumber = styled.span`
  padding: 0.3em;
  vertical-align: middle;
  color: ${({theme, active}) => active ? theme.colors.text : theme.colors.textSecondary};
  line-height: 0;
  font-style: normal;
  font-size: ${px2rem(24)};
  ${media.down('mobile')(css`
    ${typography.h2}
  `)}
`

const StyledText = styled.span`
  position: absolute;
  top: 120%;
  white-space: pre;
  text-align: center;
  color: ${({theme, active}) => active ? theme.colors.text : theme.colors.textSecondary};
  ${typography.h2}
  ${({moreSteps}) => moreSteps && css`
    ${media.down('mobile')(css`
      white-space: unset;
    `)}
  `}
  ${media.down('mobile')(css`
    ${typography.h4}
  `)}
`

const Step = ({active, checked, number, clickable, moreSteps, children, ...props}) => {
  return (
    <StyledStepContainer moreSteps={moreSteps}>
      <StyledStep active={active} checked={checked} clickable={clickable} {...props}>
        <StyledNumber active={active}>
          <Choose>
            <When condition={checked}>
              <img src={Checkmark} />
            </When>
            <Otherwise>
              {number}
            </Otherwise>
          </Choose>
        </StyledNumber>
      </StyledStep>
      <StyledText active={active} moreSteps={moreSteps}>
        {children}
      </StyledText>
    </StyledStepContainer>
  )
}

Step.propTypes = {
  active: PropTypes.bool,
  checked: PropTypes.bool,
  number: PropTypes.number,
  clickable: PropTypes.bool,
  moreSteps: PropTypes.bool,
  children: PropTypes.node,
}

export default Step
