import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {keys} from 'lodash-es'
import theme from '../theme/theme'
import * as typography from '../typography'
import Autolayout from '../atoms/Autolayout'


const backgroundColors = {
  primary: 'primary2',
  primary2: 'primary',
  background1: 'text',
  background2: 'textMuted',
  text: 'background1',
  textInverted: 'textSecondary',
  textSecondary: 'background1',
  textMuted: 'background1',
  positive: 'positive2',
  positive2: 'positive',
  warning: 'warning2',
  warning2: 'warning',
  negative: 'negative2',
  negative2: 'negative',
  auxiliary: 'auxiliary2',
  auxiliary2: 'auxiliary',
}

const StyledLegend = styled.legend`
  float: left;
  padding-bottom: ${({theme}) => theme.spacer};
  ${typography.h2}

  & + * {
    clear: left;
  }
`

const StyledFieldset = styled.fieldset`
  padding: ${({theme}) => theme.spacer};
  border: ${({theme}) => theme.border.size} solid ${({theme}) => theme.border.color};
  border-radius: ${({theme}) => theme.border.radius};
  ${({theme, $color}) => $color && css`
    border-color: ${theme.colors[$color]};
    background-color: ${theme.colors[backgroundColors[$color]]};
  `}
`

const FormSection = ({title, children, color}) => {
  return (
    <StyledFieldset $color={color}>
      <StyledLegend>{title}</StyledLegend>
      <Autolayout column>
        {children}
      </Autolayout>
    </StyledFieldset>
  )
}

FormSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  color: PropTypes.oneOf(keys(theme.colors)),
}

export default FormSection
