import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {If} from 'babel-plugin-jsx-control-statements'
import media from '../../frontend/utils/media'
import {px2rem} from '../theme/theme'
import Background from '../assets/background.jpg'
import LogoBlue from '../assets/logo-blue.svg'
import StoreBadge from '../atoms/StoreBadge'
import Autolayout from '../atoms/Autolayout'


const StyledDiv = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  max-width: 100vw;
  max-height: 100vh;
  background:
    linear-gradient(180deg, rgba(32, 31, 75, 0.6) 0%, rgba(13, 12, 34, 0.93) 100%),
    url("${Background}") no-repeat top right 15vw;
  background-size: cover;
`

const StyledMain = styled.main`
  box-sizing: border-box;
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: ${({theme}) => theme.spacer};
  width: ${px2rem(611)};
  max-width: 100vw;
  height: 100%;
  max-height: 100vh;
  ${media.down('mobile')(css`
    width: 100vw;
  `)}
  padding: calc(4 * ${({theme}) => theme.spacer});
  background-color: white;
  color: black;
`

const StyledFooter = styled.footer`
  margin-top: auto;
  text-align: center;
`

const StyledBrand = styled.img`
  max-width: ${px2rem(100)};
  margin-bottom: ${({theme}) => theme.spacer};
`

const LoginLayout = ({webLink, iosLink, playLink, language, children}) => {
  return (
    <StyledDiv>
      <StyledMain>
        {children}
        <StyledFooter>
          <a href={webLink} target="_blank" rel="noreferrer noopener">
            <StyledBrand src={LogoBlue} alt="Grason" />
          </a>
          <Autolayout crossAxis="center" mainAxis="center">
            <If condition={iosLink}>
              <StoreBadge store="ios" language={language} link={iosLink} />
            </If>
            <If condition={playLink}>
              <StoreBadge store="play" language={language} link={playLink} />
            </If>
          </Autolayout>
        </StyledFooter>
      </StyledMain>
    </StyledDiv>
  )
}

LoginLayout.propTypes = {
  webLink: PropTypes.string.isRequired,
  iosLink: PropTypes.string,
  playLink: PropTypes.string,
  language: PropTypes.string,
  children: PropTypes.node,
}

export default LoginLayout
