

export const API = '/flexi/v3'

export const API_ME = '/me'
export const API_ME_CHANGE_PASSWORD = '/me/change-password'
export const API_ME_PHONE = '/me/phone'
export const API_ME_REQUEST_CODE = '/me/phone/request-code'
export const API_CONFIG = '/config'
export const API_EVENT = '/event'
export const API_SIGNUP = '/business/signup/form'
export const API_REGISTER = '/business/signup'
export const API_REGISTER_STEP1 = '/business/signup/step-1'
export const API_REGISTER_STEP2 = '/business/signup/step-2'
export const API_REGISTER_VERIFY_EMAIL = '/business/signup/verify-email'
export const API_REGISTER_VERIFY_PHONE = '/business/signup/verify-phone'
export const API_LOGIN = '/login'
export const API_FILE = '/file'
export const API_PUBLIC_ADS = '/ads'
export const API_ACTIVE_SUBSCRIPTION = '/business/:businessId/subscription'
export const API_DASHBOARD = '/business/:businessId/dashboard'
export const API_FORGOT_PASSWORD = '/login/forget-password'
export const API_NOTIFICATIONS = '/business/:businessId/notifications'
export const API_NOTIFICATIONS_READ = '/business/:businessId/notifications/mark-as-read/:notificationId'
export const API_PLACES = '/business/:businessId/places'
export const API_JOBS = '/business/:businessId/jobs'
export const API_JOBS_DETAIL = '/business/:businessId/jobs/:jobId'
export const API_JOBS_COPY = '/business/:businessId/jobs/:jobId/copy'
export const API_JOB_PICK = '/business/:businessId/jobs/pick'
export const API_JOB_ABSENT = '/business/:businessId/jobs/absent'
export const API_JOB_REMOVE = '/business/:businessId/jobs/remove'
export const API_JOB_ATTENDANCE = '/business/:businessId/jobs/attendance'
export const API_JOB_COMMENTS = '/jobs/:jobId/comments'
export const API_ADS = '/business/:businessId/ads'
export const API_AD_DETAIL = '/business/:businessId/ads/:adId'
export const API_AD_COPY = '/business/:businessId/ads/:adId/copy'
export const API_AD_HIRE = '/business/:businessId/ads/hire'
export const API_AD_FIRE = '/business/:businessId/ads/fire'
export const API_AD_PROCESSING = '/business/:businessId/ads/processing'
export const API_AD_SUMMARY = '/business/:businessId/ads/summary'
export const API_BUSINESS = '/business/:businessId'
export const API_MANAGERS = '/business/:businessId/managers'
export const API_MANAGERS_DETAIL = '/business/:businessId/managers/:managerId'
export const API_RATING = '/business/:businessId/rating'
export const API_RATING_ATTRIBUTES = '/business/:businessId/rating/attribute'
export const API_REPORTS = '/business/:businessId/reports'
export const API_REPORT_CSV = '/business/:businessId/reports/:reportId/csv'
export const API_REPORT_XLSX = '/business/:businessId/reports/:reportId/xlsx'
export const API_TEAM = '/business/:businessId/team'
export const API_TEAM_AVAILABLE = '/business/:businessId/team/available'
export const API_TEAM_FAVORITE = '/business/:businessId/team/:grasonId'
export const API_ADDRESS = '/business/:businessId/address'
export const API_DIGI_CONTRACTS = '/business/:businessId/digi/contracts'
export const API_FAVORITE_GRASON = '/business/:businessId/favorite/grason/:grasonId'
export const API_PROMOCODE = '/business/:businessId/promocode'
export const API_GRASON_RATINGS = '/rating/grason/:grasonId'
export const API_TRANSLATE = '/../../common/v1/translate'
export const API_ARES = '/../../common/v1/ares'


// Frontend routes
export const DASHBOARD = '/'
export const CREATE = 'create'
export const ME = 'me'
export const JOBS = '/jobs'
export const JOBS_PAST = '/jobs/past'
export const JOBS_DETAIL = '/jobs/:jobId'
export const JOBS_EDIT = '/jobs/:jobId/edit'
export const JOBS_CREATE = '/jobs/create'

export const ADS = '/ads'
export const ADS_CREATE = '/ads/create'
export const ADS_COPY_AD = '/ads/copy/:adId'
export const ADS_DETAIL = '/ads/:adId'
export const ADS_EDIT = '/ads/:adId/edit'


export const BUSINESS = '/business'
export const BUSINESS_SUBSCRIPTIONS = '/business/subscriptions'
export const BUSINESS_MANAGERS = '/business/managers'
export const BUSINESS_MANAGERS_CREATE = '/business/managers/create'
export const BUSINESS_MANAGERS_ME = '/business/managers/me'
export const BUSINESS_TEAM = '/business/team'
export const BUSINESS_CONTRACTS = '/business/contracts'
export const BUSINESS_REPORTS = '/business/reports'

export const NOTIFICATIONS = '/notifications'
export const PROFILE = '/profile'
export const HELP = '/help'
export const TUTORIAL = '/tutorial'
export const LOGIN = '/login'
export const REGISTRATION = '/registration'
export const REGISTRATION_SUCCESS = '/registration/success'
