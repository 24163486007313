import {omitBy, pickBy, isUndefined, mapValues, isEmpty} from 'lodash'
import {isString} from 'lodash-es'
import * as routes from '../../../constants/routes'
import generatePath from '../../lib/generatePath'
import config from '../config'
import {fallbackLng} from '../i18n'


const stringify = (value) => isString(value) ? value : JSON.stringify(value)
export const getQueryString = (query) => {
  const searchParams = omitBy(mapValues(query, stringify), isUndefined)
  return !isEmpty(searchParams) ? `?${new window.URLSearchParams(searchParams).toString()}` : ''
}


export const api = async (method, pathPattern, options) => {
  const {data, query, params, sessionToken, language, asApp, asAdmin, asMultipart, detectLanguage} = options || {}
  const path = generatePath(pathPattern, params)
  const search = getQueryString(query)
  const hasBody = !['HEAD', 'GET'].includes(method.toUpperCase())
  const headers = pickBy({
    'Content-type': hasBody && !asMultipart ? 'application/json' : undefined,
    'Accept-Language': language ?? fallbackLng,
    'Authorization': sessionToken ? `Bearer ${sessionToken}` : undefined,
    'X-User-Agent': asApp ? config.userAgent : undefined,
    'X-Grason-Admin': asAdmin ? '1' : undefined,
    'X-Grason-DetectLanguage': detectLanguage ? 'true' : undefined,
  })
  const body = hasBody
    ? (asMultipart ? data : JSON.stringify(data || {}))
    : undefined
  return window.fetch(config.apiUrl + routes.API + path + search, {
    method,
    headers,
    body,
    credentials: 'include',
  })
    .catch(() => {
      throw new Error('Server unavailable')
    })
    .then((res) => {
      const contentType = res.headers.get('content-type') || ''
      if (contentType.indexOf('application/json') > -1) {
        return res.json().then((data) => ({res, data}))
      } else if (contentType.indexOf('application/octet-stream') > -1) {
        return res.blob().then((data) => ({res, data}))
      } else {
        return res.text().then((data) => ({res, data}))
      }
    })
    .then(({res, data}) => {
      if (res.ok) {
        return data
      } else {
        const error = new Error(data && data.message || res.statusText || 'Unknown error')
        error.data = data
        error.status = res.status
        throw error
      }
    })
}
