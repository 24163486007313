import * as Sentry from '@sentry/react'
import config from './config'


if (config.sentry) {
  Sentry.init({
    dsn: config.sentry,
    release: `grason-flexi-frontend@${config.version}`,
    environment: config.environment,
  })
}

export default Sentry
