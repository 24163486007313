import PropTypes from 'prop-types'
import styled, {css, useTheme} from 'styled-components'
import {isString, keys} from 'lodash-es'
import {If, Choose, When} from 'babel-plugin-jsx-control-statements'
import {darken} from 'polished'
import theme, {px2rem} from '../theme/theme'
import * as typography from '../typography'
import Icon from './Icon'
import Spinner from './Spinner'


const StyledText = styled.span`
  padding-left: ${({theme, gap}) => !gap ? 0 : theme.spacer};
`

const StyledIcon = styled(Icon)`
  ${({variant}) => variant === 'plain' && css`
    line-height: 0.85;
    align-self: start;
  `}
  ${({iconColor}) => iconColor && css`
    color: ${({iconColor}) => theme.colors[iconColor]};
  `}
`

const applyVariant = ({theme, variant, $background, $foreground, disabled, size, onClick, gap}) => {
  if (variant === 'plain') {
    return css`
      background: none;
      padding-left: 0;
      padding-right: 0;
      cursor: pointer;
      white-space: pre;
      ${gap && css`
        margin-left: calc(0.5 * ${({theme}) => theme.spacer});
      `}
      ${size === 'block' && css`
        justify-content: center;
      `}
      ${disabled ? css`
        color: ${theme.colors.textMuted};
        pointer-events: none;
      ` : css`
        color: ${theme.colors[$background]};
      `}

      ${StyledText} {
        padding-left: calc(0.25 * ${({theme}) => theme.spacer});
      }

      &:hover,
      &:focus {
        ${!disabled && css`
          ${StyledText} {
            text-decoration: underline;
          }
        `}
      }
    `
  } else {
    const background = theme.colors[$background]
    const foreground = theme.colors[$foreground]
    return css`
      justify-content: center;
      padding: ${theme.spacer};
      border-radius: ${theme.border.radius};
      color: ${foreground};
      background-color: ${background};
      cursor: pointer;
      white-space: pre;
      ${disabled && css`
        box-shadow: none;
        cursor: not-allowed;
      `}

      &:hover,
      &:focus {
        ${!disabled && onClick && css`
          background-color: ${darken(0.12, background)};
        `}
      }

      &:active {
        ${!disabled && onClick && css`
          background-color: ${darken(0.12, background)};
          box-shadow: none;
        `}
      }
    `
  }
}

const sizes = {
  default: css`
    display: inline-flex;
    min-width: ${px2rem(128)};
  `,
  short: css`
    display: inline-flex;
  `,
  block: css`
    display: flex;
    margin-right: 0;
    width: 100%;
  `,
}

const StyledButton = styled.button`
  align-items: center;
  ${typography.h3}
  border: 0;
  ${applyVariant}
  ${({size}) => sizes[size]}
`

const Button = ({
  color, component, disabled, loading, hideText, iconName, iconColor, children, onClick, tooltip, variant, ...props
}) => {
  const theme = useTheme()
  const disabledColor = disabled && isString(disabled) ? disabled : 'textMuted'
  const background = disabled ? disabledColor : color
  const foreground = theme.textColor[background]
  const handleClick = (e) => {
    if (disabled) {
      e.preventDefault()
      return
    }

    return onClick(e)
  }

  return (
    <StyledButton
        as={component}
        variant={variant}
        disabled={loading || disabled}
        onClick={onClick && handleClick}
        $background={background}
        $foreground={foreground}
        {...props}
    >
      <Choose>
        <When condition={loading}>
          <Spinner size={10} border={2} color={foreground} label={loading} />
        </When>
        <When condition={iconName}>
          <StyledIcon
              size="large"
              label={hideText && children}
              name={iconName}
              iconColor={iconColor}
              variant={variant}
              tooltip={tooltip}
          />
        </When>
      </Choose>
      <If condition={!hideText}><StyledText gap={loading || iconName}>{children}</StyledText></If>
    </StyledButton>
  )
}

Button.defaultProps = {
  color: 'primary',
  variant: 'filled',
  size: 'default',
  component: 'button',
}

Button.propTypes = {
  color: PropTypes.oneOf(keys(theme.colors)),
  iconColor: PropTypes.oneOf(keys(theme.colors)),
  variant: PropTypes.oneOf(['plain', 'filled']),
  size: PropTypes.oneOf(keys(sizes)),
  component: PropTypes.elementType,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(keys(theme.colors))]),
  loading: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  iconName: PropTypes.string,
  hideText: PropTypes.bool,
  tooltip: PropTypes.bool,
  gap: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func,
}

export default Button
