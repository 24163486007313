import {Spinner, Backdrop} from '../../../uikit'


const Loading = () => (
  <Backdrop>
    <Spinner />
  </Backdrop>
)

export default Loading
