import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import media from '../../frontend/utils/media'
import {px2rem} from '../theme/theme'
import Background from '../assets/registrationBackground.jpg'
import LogoBlue from '../assets/logo-blue.svg'
import Autolayout from '../atoms/Autolayout'
import * as typography from '../typography'


const StyledLayout = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
`

const StyledBackground = styled.div`
  position: relative;
  width: 33.33%;
  height: 100%;
  background-image: url("${Background}");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 2.5rem;
  ${media.down('mobile')(css`
    display: none;
  `)}
`

const StyledBrandContainer = styled.div`
  display: flex;
  align-items: center;
`

const StyledBrand = styled.img`
  max-width: ${px2rem(100)};
  margin-right: ${({theme}) => theme.spacer};
`

const StyledMain = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: ${({theme}) => theme.spacer};
  width: 66.66%;
  max-width: 66.66%;
  padding: 2.5rem;
  overflow: scroll;
  ${media.down('mobile')(css`
    width: 100vw;
    max-width: 100%;
    padding: 2.5rem 1rem;
  `)}
`

const StyledTopRightLink = styled.div`
  display: flex;
  justify-content: end;
  ${media.down('mobile')(css`
    display: none;
  `)}
`

const StyledHeaderContainer = styled.div`
  ${media.down('mobile')(css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `)}
`

const StyledTitleContainer = styled.div`
  text-align: center;
  margin-bottom: 1rem;
  ${media.down('mobile')(css`
    text-align: left;
  `)}
`

const StyledPointsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: fit-content;
  margin: 0 auto;
  ${media.down('mobile')(css`
    margin: 0;
  `)}
`

const StyledPoint = styled.div`
  display: flex;
  width: 100%;
  ${typography.h2}
  font-weight: normal;
  line-height: 1.5;
`

const StyledCheckmarkContainer = styled.div`
  padding-right: 0.5rem;

  img {
    max-height: ${px2rem(11)};
  }
`

const StyledLogosContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 3.25rem;
  margin: 2.75rem auto;
  filter: grayscale(100%);
  opacity: 70%;
  width: 100%;
  max-width: 30rem;
  ${media.down('mobile')(css`
    max-width: unset;
    gap: 2rem 3.25rem;
    margin: 2rem 0;
  `)}

  img {
    max-height: ${px2rem(60)};
    max-width: ${px2rem(100)};
    object-fit: contain;
    ${media.down('mobile')(css`
      width: ${px2rem(80)};
      max-width: ${px2rem(80)};
    `)}
  }
`

const StyledIconContainer = styled.div`
  ${media.up('mobile')(css`
    display: none;
  `)}
`

const StyledFooter = styled.footer`
  margin-top: auto;
`

export const RegistrationPointsContainer = ({children}) => {
  return (
    <StyledPointsContainer>
      {children}
    </StyledPointsContainer>
  )
}

RegistrationPointsContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

export const RegistrationPoint = ({children}) => {
  return (
    <StyledPoint>
      {children}
    </StyledPoint>
  )
}

RegistrationPoint.propTypes = {
  children: PropTypes.node.isRequired,
}

export const RegistrationCheckmarkContainer = ({children}) => {
  return (
    <StyledCheckmarkContainer>
      {children}
    </StyledCheckmarkContainer>
  )
}

RegistrationCheckmarkContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

export const RegistrationLogosContainer = ({children}) => {
  return (
    <StyledLogosContainer>
      {children}
    </StyledLogosContainer>
  )
}

RegistrationLogosContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

export const RegistrationActions = ({children}) => {
  return (
    <StyledFooter>
      <Autolayout mainAxis="justify" responsive>
        {children}
      </Autolayout>
    </StyledFooter>
  )
}

RegistrationActions.propTypes = {
  children: PropTypes.node.isRequired,
}

const RegistrationLayout = ({webLink, title, backLink, topRightLink, closeIcon, children, ...props}) => {
  return (
    <StyledLayout {...props}>
      <StyledBackground>
        <StyledBrandContainer>
          <a href={webLink} target="_blank" rel="noreferrer noopener">
            <StyledBrand src={LogoBlue} alt="Grason" />
          </a>
          {backLink}
        </StyledBrandContainer>
      </StyledBackground>
      <StyledMain>
        <StyledTopRightLink>
          {topRightLink}
        </StyledTopRightLink>
        <StyledHeaderContainer>
          <StyledTitleContainer>{title}</StyledTitleContainer>
          <StyledIconContainer>
            {closeIcon}
          </StyledIconContainer>
        </StyledHeaderContainer>
        {children}
      </StyledMain>
    </StyledLayout>
  )
}

RegistrationLayout.propTypes = {
  webLink: PropTypes.string.isRequired,
  title: PropTypes.node,
  backLink: PropTypes.node,
  topRightLink: PropTypes.node,
  closeIcon: PropTypes.node,
  children: PropTypes.node,
}

export default RegistrationLayout
