import PropTypes from 'prop-types'
import {useEffect} from 'react'
import {Helmet} from 'react-helmet'
import config from '../../config'


export const gtmEvent = (event) => {
  if (!window.dataLayer) return window.console.error('GTM not loaded')
  return window.dataLayer.push(event)
}

const GoogleAnalytics = ({history, children}) => {
  useEffect(() => {
    if (!config.googleAnalytics || !window.gtag) return
    const handleNavigate = ({location}) => {
      window.gtag('set', 'page_path', location.pathname)
      window.gtag('event', 'page_view')
    }
    return history.listen(handleNavigate)
  }, [history])

  if (!config.googleAnalytics) return children
  return (
    <>
      <Helmet>
        <script async src={`https://www.googletagmanager.com/gtm.js?id=${config.googleAnalytics}`} />
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({'gtm.start': new Date().getTime(), event:'gtm.js'});
          `}
        </script>
      </Helmet>
      {children}
    </>
  )
}

GoogleAnalytics.propTypes = {
  history: PropTypes.object.isRequired,
  children: PropTypes.node,
}

export default GoogleAnalytics
