import invariant from 'invariant'
import {forEach, set, get, isFunction, isRegExp, isArray} from 'lodash-es'


export const showErrorOnChange = ({
  meta: {submitError, dirtySinceLastSubmit, error, touched, modified}, helperText,
}) => {
  const isError = Boolean(((submitError && !dirtySinceLastSubmit) || error) && (touched || modified))
  const helperTextOrError = isError ? error || submitError : helperText

  return {isError, helperTextOrError}
}

export const showErrorOnBlur = ({
  meta: {submitError, dirtySinceLastSubmit, error, touched}, helperText,
}) => {
  const isError = Boolean(((submitError && !dirtySinceLastSubmit) || error) && touched)
  const helperTextOrError = isError ? error || submitError : helperText

  return {isError, helperTextOrError}
}

export const validatePatterns = (errors, values, fields) => {
  invariant(values, 'No values provided')

  forEach(fields, (rule, key) => {
    const value = get(values, key, undefined)
    const error = get(errors, key, undefined)
    if (error) return // One error per field
    if (value === undefined) return set(errors, key, 'validations.missing')
    if (isFunction(rule)) {
      if (rule(value)) set(errors, key, rule(value))
      return
    }
    const regexp = isArray(rule) ? rule[0] : rule
    invariant(isRegExp(regexp), 'Invalid rule')
    const message = isArray(rule) ? rule[1] : 'validations.invalid'
    if (!String(value).match(regexp)) return set(errors, key, message)
  })

  return errors
}
