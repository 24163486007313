import './sentry'

import {createRoot} from 'react-dom/client'
import App from './App'


const div = document.createElement('div')
document.getElementsByTagName('body')[0].appendChild(div)

createRoot(div).render(<App />)
