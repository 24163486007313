import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'
import resourcesToBackend from 'i18next-resources-to-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import {formatDistanceStrict} from 'date-fns'
import {cs, sk, enGB} from 'date-fns/locale'


export const formatLocales = {cs, sk, en: enGB}
export const supportedLngs = ['cs', 'sk', 'en']
export const fallbackLng = 'en'
const fallbackFormatLocale = formatLocales[fallbackLng]

const loadMessages = {
  cs: () => import(/* webpackChunkName: "locale-cs" */ './messages/cs.json'),
  sk: () => import(/* webpackChunkName: "locale-sk" */ './messages/sk.json'),
  en: () => import(/* webpackChunkName: "locale-en" */ './messages/en.json'),
}

const lazyLoadResources = resourcesToBackend((language, _namespace, callback) => {
  if (!loadMessages[language]) return callback('Unsupported', null)

  loadMessages[language]()
    .then((resources) => {
      callback(null, resources)
    })
    .catch((error) => {
      callback(error, null)
    })
})

i18n
  .use(lazyLoadResources)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs,
    nonExplicitSupportedLngs: true,
    fallbackLng,
    debug: true,
    interpolation: {
      escapeValue: false, // React escapes
    },
  })

i18n.services.formatter.add('capitalize', (value) => {
  return value.charAt(0).toUpperCase() + value.slice(1)
})
i18n.services.formatter.add('formatdistancestrict', (value, lng = fallbackLng) => {
  const locale = formatLocales[lng] || fallbackFormatLocale
  return formatDistanceStrict(value, new Date(), {locale})
})

export default i18n
