import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {forwardRef} from 'react'
import media from '../../frontend/utils/media'
import omitProps from '../../frontend/utils/omitProps'


const alignmentMap = {
  start: 'flex-start',
  end: 'flex-end',
  center: 'center',
  stretch: 'stretch',
  justify: 'space-between',
  baseline: 'baseline',
}


const StyledDiv = styled('div').withConfig(omitProps(['mainAxis']))`
  ${({$column, $width, $height}) => $column
    ? css`
      display: flex;
      width: ${$width === 'fill' ? '100%' : $width === 'hug' ? 'auto' : $width};
      height: ${$height === 'fill' ? '100%' : $height === 'hug' ? 'auto' : $height};
    `
    : css`
      display: ${$width === 'fill' ? 'flex' : 'inline-flex'};
      width: ${$width === 'fill' ? '100%' : $width === 'hug' ? 'auto' : $width};
      height: ${$height === 'fill' ? '100%' : $height === 'hug' ? 'auto' : $height};
    `}
  flex-direction: ${({$column, $reverse}) => `${$column ? 'column' : 'row'}${$reverse ? '-reverse' : ''}`};
  justify-content: ${({mainAxis}) => alignmentMap[mainAxis]};
  align-items: ${({crossAxis}) => alignmentMap[crossAxis]};
  ${({$wrap}) => $wrap && css`
    flex-wrap: wrap;
  `}
  ${({theme, gap}) => gap && css`
    gap: ${theme.spacer};
  `}
  ${({distribute}) => distribute && css`
    & > * {
      flex-basis: 100%;
      flex-shrink: 1;
    }
  `}

  ${({responsive, $column, $reverse}) => responsive && css`
    ${media.down('desktop')(css`
      ${!$column && css`
        display: flex;
        flex-direction: column${$reverse ? '-reverse' : ''};
        justify-content: ${({crossAxis}) => alignmentMap[crossAxis]};
        align-items: ${({mainAxis}) => alignmentMap[mainAxis]};
      `}
      width: 100%;
    `)}
  `}

  ${({responsive, distribute}) => responsive && distribute && media.down('mobile')(css`
    & > * {
      flex-shrink: 0;
    }
  `)}
`

const Autolayout = forwardRef(({
  component, column, reverse, mainAxis, crossAxis, width, height, gap, responsive, wrap, distribute, ...props
}, ref) => {
  return (
    <StyledDiv
        ref={ref}
        as={component}
        $column={column}
        $reverse={reverse}
        mainAxis={mainAxis}
        crossAxis={crossAxis}
        $width={width}
        $height={height}
        gap={gap}
        responsive={responsive}
        $wrap={wrap}
        distribute={distribute}
        {...props}
    />
  )
})
Autolayout.displayName = 'Autolayout'
Autolayout.defaultProps = {
  component: 'div',
  gap: true,
  mainAxis: 'start',
  crossAxis: 'stretch',
  width: 'fill',
}

Autolayout.propTypes = {
  component: PropTypes.elementType,
  column: PropTypes.bool,
  reverse: PropTypes.bool,
  mainAxis: PropTypes.oneOf(['start', 'center', 'end', 'justify', 'baseline']),
  crossAxis: PropTypes.oneOf(['start', 'center', 'end', 'stretch']),
  width: PropTypes.oneOfType([PropTypes.oneOf(['fill', 'hug']), PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.oneOf(['fill', 'hug']), PropTypes.string]),
  gap: PropTypes.bool,
  responsive: PropTypes.bool,
  wrap: PropTypes.bool,
  distribute: PropTypes.bool,
}

export default Autolayout
