import {createContext, useContext} from 'react'
import PropTypes from 'prop-types'
import {isFunction} from 'lodash'
import {Form} from 'react-final-form'
import getDisplayName from 'react-display-name'
import {useTranslation} from 'react-i18next'
import {Autolayout} from '../../../uikit'


const FormContext = createContext({})

export const useFormCtx = () => {
  return useContext(FormContext) || {}
}

const identity = (values) => values

const withForm = ({validate, format, parse} = {}) => (WrappedComponent) => {
  const Final = ({onSubmit, initialValues, children, context, ...rest}) => {
    const {t} = useTranslation()
    const usedValidate = validate || (() => ({}))
    const usedParse = parse || identity
    const usedFormat = format || identity
    return (
      <Form
          keepDirtyOnReinitialize
          onSubmit={(...args) => onSubmit(usedParse(...args))}
          initialValues={initialValues && usedFormat(initialValues)}
          validate={(values) => usedValidate(values, {t, ...rest})}
          {...rest}
      >
        {({handleSubmit, ...formProps}) => {
          const form = (
            <FormContext.Provider value={context}>
              <Autolayout column component="form" onSubmit={handleSubmit}>
                <WrappedComponent handleSubmit={handleSubmit} {...formProps} />
              </Autolayout>
            </FormContext.Provider>
          )

          if (isFunction(children)) {
            return children({handleSubmit, ...formProps, form})
          }
          return form
        }}
      </Form>
    )
  }

  Final.propTypes = {
    initialValues: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    children: PropTypes.func,
    context: PropTypes.object,
  }

  Final.displayName = `FinalForm(${getDisplayName(WrappedComponent)})`
  return Final
}

export default withForm
