import invariant from 'invariant'
import {ReactQueryDevtools} from 'react-query/devtools'
import {QueryClient, QueryClientProvider, useMutation, useQuery} from 'react-query'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'
import {isEmpty} from 'lodash'
import {useMemo} from 'react'
import {keyBy} from 'lodash-es'
import * as routes from '../../../constants/routes'
import {api} from '../utils/api'
import {downloadFile} from '../utils/downloadFile'
import {isAdminSession, readSession, useSession} from './auth'


const queryFn = async ({queryKey}) => {
  const session = readSession()
  const asAdmin = isAdminSession()
  const [route, options] = queryKey
  return api(options.method || 'GET', route, {...options, sessionToken: session?.token, asAdmin})
}

const client = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      suspense: true,
      queryFn,
      useErrorBoundary: true,
    },
  },
})

export const ApiProvider = ({children}) => {
  return (
    <QueryClientProvider client={client}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </QueryClientProvider>
  )
}

ApiProvider.propTypes = {
  children: PropTypes.node,
}

export const useMe = ({config = {}, ...rest} = {}) => {
  invariant(isEmpty(rest), 'Unsupported option')
  const {manager} = useSession()
  const {i18n} = useTranslation()

  const options = {
    language: i18n.resolvedLanguage,
  }
  const {data, refetch, isFetching} = useQuery({
    queryKey: [routes.API_ME, options],
    staleTime: 15 * 60 * 1000,
    ...config,
    initialData: {manager},
  })

  return [data?.manager, {refetch, isFetching}]
}

export const useConfig = ({...rest} = {}) => {
  invariant(isEmpty(rest), 'Unsupported option')
  const {i18n} = useTranslation()
  const options = {
    language: i18n.resolvedLanguage,
    asApp: true,
  }
  const {data} = useQuery({
    queryKey: [routes.API_CONFIG, options],
    staleTime: 24 * 60 * 60 * 1000,
  })

  return data
}

export const useCountries = () => {
  const config = useConfig()
  return useMemo(() => keyBy(config.countries, 'code'), [
    config.countries,
  ])
}

export const useListQuery = ({route, extraOptions = {}, params, query, config = {}, ...rest} = {}) => {
  invariant(isEmpty(rest), 'Unsupported option')
  const {i18n} = useTranslation()

  const options = {
    language: i18n.resolvedLanguage,
    params,
    query,
    ...extraOptions,
  }
  const {data, refetch, isFetching} = useQuery({
    queryKey: [route, options],
    ...config,
  })

  return {data, refetch, isFetching}
}

export const useActiveSubscription = ({businessId, extraOptions = {}, config = {}, ...rest}) => {
  invariant(isEmpty(rest), 'Unsupported option')
  const {i18n} = useTranslation()

  // This endpoint returns 402 when no subscription but react-query does not cache errors
  const customQueryFn = async (...args) => {
    try {
      return await queryFn(...args)
    } catch (_e) {
      return null
    }
  }

  const options = {
    language: i18n.resolvedLanguage,
    params: {businessId},
    ...extraOptions,
  }
  const {data, refetch, isFetching} = useQuery({
    queryFn: customQueryFn,
    queryKey: [routes.API_ACTIVE_SUBSCRIPTION, options],
    ...config,
  })

  return {data, refetch, isFetching}
}

export const getTeamQueryKey = ({businessId}) => ['team', businessId]
export const useTeam = ({businessId}) => {
  const {i18n} = useTranslation()
  const {data, refetch, isFetching} = useQuery({
    queryKey: getTeamQueryKey({businessId}),
    queryFn: async () => {
      const session = readSession()
      const asAdmin = isAdminSession()
      const options = {
        language: i18n.resolvedLanguage,
        sessionToken: session?.token,
        asAdmin,
        params: {businessId},
        detectLanguage: true,
      }
      const [availableGrasons, favoriteGrasons] = await Promise.all([
        api('GET', routes.API_TEAM_AVAILABLE, options),
        api('GET', routes.API_TEAM, options),
      ])
      return {availableGrasons, favoriteGrasons}
    },
  })
  return {data, refetch, isFetching}
}

export const useApiMutation = ({route, params, method = 'POST', config = {}, unwrapData = false, ...rest}) => {
  invariant(isEmpty(rest), 'Unsupported option')
  const session = useSession()
  const {i18n} = useTranslation()

  const options = {
    sessionToken: session?.token,
    language: i18n.resolvedLanguage,
    params,
  }
  const mutation = useMutation((data) => {
    return api(method, route, {...options, ...(unwrapData ? data : {data})})
  }, {
    useErrorBoundary: true,
    ...config,
  })
  return mutation
}

export const useDownload = (route) => {
  const download = useApiMutation({
    method: 'GET',
    route,
    unwrapData: true,
  })

  return (params, filename) => async () => {
    const data = await download.mutateAsync({
      params,
    })
    downloadFile(data, filename)
  }
}
