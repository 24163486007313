import PropTypes from 'prop-types'
import {useLayoutEffect, useRef} from 'react'
import {createPortal} from 'react-dom'
import {forEach} from 'lodash-es'


const Portal = ({children, ...props}) => {
  const wrapperRef = useRef(null)
  if (wrapperRef.current === null && typeof document !== 'undefined') {
    const el = document.createElement('div')
    forEach(props, (value, key) => el.setAttribute(key, value))
    wrapperRef.current = el
  }
  useLayoutEffect(() => {
    const wrapper = wrapperRef.current
    if (!wrapper || typeof document === 'undefined') {
      return
    }
    document.body.appendChild(wrapper)
    return () => {
      document.body.removeChild(wrapper)
    }
  }, [wrapperRef])
  return createPortal(children, wrapperRef.current)
}

Portal.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Portal
