import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {If, For} from 'babel-plugin-jsx-control-statements'
import {px2rem} from '../theme/theme'
import * as typography from '../typography'
import Icon from '../atoms/Icon'
import {fieldPropTypes} from './common'


const StyledAddon = styled.div`
  padding: 0 ${({theme}) => theme.spacer};
  ${typography.input}
`

const StyledChevron = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  padding: 0 ${({theme}) => theme.spacer};
  ${typography.input}
  color: ${({theme}) => theme.colors.textMuted};
  line-height: 0.5;
`

const StyledInput = styled.select`
  appearance: none;
  height: ${px2rem(40)};
  padding: 0 calc(4 * ${({theme}) => theme.spacer}) 0 ${({theme}) => theme.spacer};
  background: none;
  border: 0;
  color: ${({theme}) => theme.colors.text};
  ${typography.input}
  cursor: pointer;

  ${/* sc-selector */ StyledAddon} + & {
    padding-left: 0;
  }

  & + ${StyledAddon} {
    padding-left: 0;
  }

  &:focus {
    outline: 0;
  }

  &:disabled {
    color: ${({theme}) => theme.colors.textSecondary};
    cursor: not-allowed;
  }

  /* Hack to override Chrome autofill background */
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }

  &:required:invalid {
    color: ${({theme}) => theme.colors.textMuted};
  }
`

const StyledInputGroup = styled.div`
  position: relative;
  display: flex;
  justify-content: stretch;
  align-items: baseline;
  height: ${px2rem(40)};
  overflow: hidden;
  background: ${({theme}) => theme.colors.background1};
  border: ${({theme}) => theme.border.size} solid ${({theme}) => theme.colors.background1};
  border-radius: ${({theme}) => theme.border.radius};
  ${({theme, invalid}) => invalid && css`
    background-color: ${theme.colors.negative2};
    border-color: ${theme.colors.negative};

    ${StyledInput}:required:invalid {
      color: transparent;
    }
  `}

  &:focus-within {
    border-color: ${({theme}) => theme.colors.primary};
  }

  ${StyledInput} {
    flex-grow: 1;
  }

  ${StyledAddon} {
    flex-grow: 0;
  }
`


const Select = ({
  name, value, onChange, onBlur, onFocus, disabled, invalid, placeholder, options, before, ...props
}) => {
  return (
    <StyledInputGroup disabled={disabled} invalid={invalid}>
      <If condition={before}>
        <StyledAddon>{before}</StyledAddon>
      </If>
      <StyledInput
          onClick={(e) => e.stopPropagation()}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          disabled={disabled}
          placeholder={placeholder}
          {...props}
          required
      >
        <option value="" disabled hidden>{placeholder}</option>
        <For each="option" of={options}>
          <option value={option.value} key={option.value}>{option.label}</option>
        </For>
      </StyledInput>
      <StyledChevron>
        <Icon name="keyboard_arrow_down" />
      </StyledChevron>
    </StyledInputGroup>
  )
}

Select.defaultProps = {

}

Select.propTypes = {
  ...fieldPropTypes,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
  })).isRequired,
  before: PropTypes.node,
}

export default Select
