import PropTypes from 'prop-types'
import {useField} from 'react-final-form'
import {FormComponent, Select} from '../../../uikit'
import {showErrorOnChange} from '../../utils/forms'
import {useFormCtx} from './withForm'


const SelectInput = ({
  name, label, helperText, fieldProps, showError = showErrorOnChange, ...props
}) => {
  const ctx = useFormCtx()
  const {input: {value, onChange, ...restInput}, meta} = useField(name, fieldProps)
  const {isError, helperTextOrError} = showError({meta, helperText})

  return (
    <FormComponent invalid={isError} label={label} helperText={helperTextOrError}>
      <Select
          value={value}
          disabled={ctx.disabled}
          invalid={isError}
          {...restInput}
          onChange={onChange}
          {...props}
      />
    </FormComponent>
  )
}

SelectInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  helperText: PropTypes.string,
  showError: PropTypes.func,
  fieldProps: PropTypes.object,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default SelectInput
