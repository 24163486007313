import PropTypes from 'prop-types'
import styled from 'styled-components'
import {If} from 'babel-plugin-jsx-control-statements'
import {px2rem} from '../theme/theme'


const StyledLabel = styled.label`
  width: 100%;
  color: ${({theme, invalid}) => theme.colors[invalid ? 'negative' : 'textSecondary']};
`

const StyledHelper = styled.span`
  display: block;
  padding-top: ${({theme}) => theme.spacer};
  text-align: left;
`

const StyledContainer = styled.div`
  margin-top: ${px2rem(4)};
`

const FormComponent = ({label, helperText, invalid, required, children, ...props}) => {
  return (
    <StyledLabel invalid={invalid} {...props}>
      {label}<If condition={required}>&nbsp;*</If>
      <StyledContainer>
        {children}
      </StyledContainer>
      <If condition={helperText}>
        <StyledHelper>{helperText}</StyledHelper>
      </If>
    </StyledLabel>
  )
}

FormComponent.propTypes = {
  label: PropTypes.string,
  helperText: PropTypes.string,
  invalid: PropTypes.bool,
  required: PropTypes.bool,
  children: PropTypes.node,
}

export default FormComponent
