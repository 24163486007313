import {createGlobalStyle, css} from 'styled-components'
import {normalize} from 'styled-normalize'
import media from '../../frontend/utils/media'
import typography from '../typography'
import theme from './theme'


export default createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
  }
  /* stylelint-disable */
  ${normalize}
  /* stylelint-enable */

  :root {
    height: 100%;
    scroll-behavior: smooth;

    /* Automatic zooming  */
    font-family: ${theme.fonts.family.text}, sans-serif;
    font-size: ${theme.responsive.zoom.fontSizeBase}px;
    line-height: ${theme.fonts.lineHeight.small};

    /* Devices too small */
    ${media.down(theme.responsive.zoom.minimalViewport)(css`
      font-size: ${theme.responsive.zoom.fontSizeMobile / (theme.responsive.zoom.minimalViewport) * 100}vw;
    `)}

    /* Mobile */
    ${media.between(theme.responsive.zoom.minimalViewport, 'mobile')(css`
      font-size: ${theme.responsive.zoom.fontSizeMobile}px;
    `)}
  }

  body {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    color: ${theme.colors.text};
    font-size: 1rem;
    overflow: hidden;

    & > div {
      height: 100%;
    }
  }

  a {
    text-decoration: none;
    color: ${theme.colors.text};
  }

  h1,
  h2,
  h3,
  h4 {
    margin: 0;
  }

  p:not(:first-child) {
    margin-block-start: calc(${({theme}) => theme.spacer} / 3);
    margin-block-end: 0;
  }

  ul {
    padding-left: 0;
  }

  ${typography}
`
