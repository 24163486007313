import {css} from 'styled-components'


export const body = css`
  font-size: ${({theme}) => theme.fonts.size.normal};
  line-height: ${({theme}) => theme.fonts.lineHeight.normal};
  font-weight: ${({theme}) => theme.fonts.weight.regular};
  letter-spacing: ${({theme}) => theme.fonts.lineHeight.base};
`

export const h1 = css`
  font-size: ${({theme}) => theme.fonts.size.extraLarge};
  line-height: ${({theme}) => theme.fonts.lineHeight.extraLarge};
  font-weight: ${({theme}) => theme.fonts.weight.bold};
  letter-spacing: ${({theme}) => theme.fonts.letterSpacing.wide};
`

export const h1Huge = css`
  font-size: ${({theme}) => theme.fonts.size.huge};
  line-height: ${({theme}) => theme.fonts.lineHeight.huge};
  font-weight: ${({theme}) => theme.fonts.weight.bold};
  letter-spacing: ${({theme}) => theme.fonts.letterSpacing.wide};
`

export const h2 = css`
  font-size: ${({theme}) => theme.fonts.size.large};
  line-height: ${({theme}) => theme.fonts.lineHeight.large};
  font-weight: ${({theme}) => theme.fonts.weight.semibold};
  letter-spacing: ${({theme}) => theme.fonts.letterSpacing.wide};
`

export const h3 = css`
  font-size: ${({theme}) => theme.fonts.size.normal};
  line-height: ${({theme}) => theme.fonts.lineHeight.normal};
  font-weight: ${({theme}) => theme.fonts.weight.semibold};
  letter-spacing: ${({theme}) => theme.fonts.letterSpacing.wider};
`

export const h4 = css`
  font-size: ${({theme}) => theme.fonts.size.small};
  line-height: ${({theme}) => theme.fonts.lineHeight.small};
  font-weight: ${({theme}) => theme.fonts.weight.semibold};
  letter-spacing: ${({theme}) => theme.fonts.letterSpacing.base};
`

export const label = css`
  font-size: ${({theme}) => theme.fonts.size.normal};
  line-height: ${({theme}) => theme.fonts.lineHeight.normal};
  font-weight: ${({theme}) => theme.fonts.weight.regular};
  letter-spacing: ${({theme}) => theme.fonts.letterSpacing.wider};
`

export const input = css`
  font-size: ${({theme}) => theme.fonts.size.large};
  line-height: ${({theme}) => theme.fonts.lineHeight.large};
  font-weight: ${({theme}) => theme.fonts.weight.regular};
  letter-spacing: ${({theme}) => theme.fonts.letterSpacing.wide};
`

export default css`
  body {
    ${body}
  }

  h1 {
    ${h1}
  }

  h2 {
    ${h2}
  }

  h3,
  strong,
  a,
  button {
    ${h3}
  }

  h4 {
    ${h4}
  }

  label {
    ${label}
  }

  input {
    ${input}
  }
`
