import PropTypes from 'prop-types'
import {navigate} from '@reach/router'
import * as routes from '../../../../constants/routes'
import {ErrorLayout, Text} from '../../../uikit'
import Button from '../containers/Button'


const ErrorFallback = ({resetError}) => {
  const handleReset = async () => {
    navigate(routes.DASHBOARD)
    resetError()
  }

  return (
    <ErrorLayout
        button={(
          <Button onClick={handleReset}>Restartovat aplikaci</Button>
        )}
    >
      <h1>Nastala neočekávaná chyba</h1>
      <Text component="p">Chybu jsme zaznamenali a co nejdříve začneme pracovat na jejím odstranění.</Text>
    </ErrorLayout>
  )
}

ErrorFallback.propTypes = {
  resetError: PropTypes.func.isRequired,
}

export default ErrorFallback
