import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {If} from 'babel-plugin-jsx-control-statements'
import {px2rem} from '../theme/theme'
import * as typography from '../typography'
import {fieldPropTypes} from './common'


const StyledAddon = styled.div`
  padding: 0 ${({theme}) => theme.spacer};
  ${typography.input}

  & > * {
    vertical-align: middle;
  }
`

const StyledInput = styled.input`
  width: 1%;
  height: ${px2rem(40)};
  padding: 0 ${({theme}) => theme.spacer};
  background: none;
  border: 0;
  color: ${({theme}) => theme.colors.text};
  ${typography.input}

  ${/* sc-selector */ StyledAddon} + & {
    padding-left: 0;
  }

  & + ${StyledAddon} {
    padding-left: 0;
  }

  &:focus {
    outline: 0;
  }

  &:disabled {
    color: ${({theme}) => theme.colors.textSecondary};
    cursor: not-allowed;
  }

  &::placeholder {
    color: ${({theme}) => theme.colors.textMuted};
  }

  /* Hack to override Chrome autofill background */
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }
`

const StyledInputGroup = styled.div`
  display: flex;
  justify-content: stretch;
  align-items: center;
  height: ${px2rem(40)};
  overflow: hidden;
  background: ${({theme}) => theme.colors.background1};
  border: ${({theme}) => theme.border.size} solid ${({theme}) => theme.colors.background1};
  border-radius: ${({theme}) => theme.border.radius};
  ${({theme, invalid}) => invalid && css`
    background-color: ${theme.colors.negative2};
    border-color: ${theme.colors.negative};

    & > ::placeholder {
      color: transparent;
    }
  `}

  &:focus-within {
    border-color: ${({theme}) => theme.colors.primary};
  }

  ${StyledInput} {
    flex-grow: 1;
  }

  ${StyledAddon} {
    flex-grow: 0;
  }
`


const Input = ({name, value, onChange, onBlur, onFocus, disabled, invalid, required, before, after, ...props}) => {
  return (
    <StyledInputGroup disabled={disabled} invalid={invalid}>
      <If condition={before}>
        <StyledAddon>{before}</StyledAddon>
      </If>
      <StyledInput
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          disabled={disabled}
          required={required}
          {...props}
      />
      <If condition={after}>
        <StyledAddon>{after}</StyledAddon>
      </If>
    </StyledInputGroup>
  )
}

Input.defaultProps = {
  type: 'text',
}

Input.propTypes = {
  ...fieldPropTypes,
  placeholder: PropTypes.string,
  type: PropTypes.oneOf(['text', 'number', 'tel', 'email', 'password', 'date', 'time']),
  before: PropTypes.node,
  after: PropTypes.node,
}

export default Input
