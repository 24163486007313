import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {keys} from 'lodash-es'
import theme from '../theme/theme'
import * as typography from '../typography'


// Explicitly list for webpack
const styles = {
  body: typography.body,
  h1: typography.h1,
  h2: typography.h2,
  h3: typography.h3,
  h4: typography.h4,
  input: typography.input,
  label: typography.label,
}

const StyledSpan = styled.span`
  ${({align}) => align && css`
    text-align: ${align};
  `}
  ${({newLines}) => newLines && css`
    white-space: pre-line;
  `}
  ${({noWrap}) => noWrap && css`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `}
  ${({textStyle}) => textStyle && styles[textStyle]}
  ${({theme, color}) => color && css`
    color: ${theme.colors[color]};
  `}
  ${({lineHeight}) => lineHeight && css`
    line-height: ${lineHeight};
  `}
`

const Text = ({component, textStyle, align, color, lineHeight, children, ...props}) => {
  if (children?.message) return 'NOT-TRANSLATED-YET'
  return (
    <StyledSpan
        as={component}
        textStyle={textStyle}
        color={color}
        lineHeight={lineHeight}
        align={align}
        {...props}
    >
      {children}
    </StyledSpan>
  )
}

Text.defaultProps = {
  component: 'span',
  textStyle: 'body',
}

Text.propTypes = {
  color: PropTypes.oneOf(keys(theme.colors)),
  textStyle: PropTypes.oneOf(keys(styles)),
  align: PropTypes.oneOf(['left', 'right', 'center', 'justify']),
  noWrap: PropTypes.bool,
  component: PropTypes.elementType,
  newLines: PropTypes.bool,
  lineHeight: PropTypes.string,
  children: PropTypes.node,
}


export default Text
