import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {keys} from 'lodash-es'
import theme, {px2rem} from '../theme/theme'


const StyledSpan = styled.span`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  ${({content}) => content && css`
  &:hover,
  &:focus {
    ::after {
      content: attr(content);
      position: absolute;
      left: 110%;
      border-radius: ${({theme}) => theme.border.radius};
      text-align: left;
      padding: ${theme.spacer};
      width: max-content;
      max-width: ${px2rem(300)};
      height: auto;
      z-index: 1;
      background-color: ${({theme, iconColor}) => iconColor ? theme.colors[iconColor] : theme.colors.text};
      white-space: initial;
      color: ${({theme, iconColor}) => iconColor
    ? theme.colors[theme.pillColor[iconColor]]
    : theme.colors.textInverted};
    }
    ::before {
      content: "";
      position: absolute;
      top: 50%;
      right: -10%;
      margin-top: -3px;
      border-width: 3px;
      border-style: solid;
      border-color: transparent ${({theme, iconColor}) => iconColor ? theme.colors[iconColor] : theme.colors.text}
      transparent transparent;
    }
  }
`}
`

const StyledIcon = styled('span').attrs({
  className: 'material-icons',
})`
  font-size: ${({theme, size}) => theme.fonts.size[size]};
  vertical-align: bottom;
  color: ${({theme, iconColor}) => theme.colors[iconColor]};
`

const Icon = ({name, label, tooltip, ...props}) => {
  return (
    <StyledSpan content={tooltip && label} {...props}>
      <StyledIcon aria-hidden={!label} aria-label={label} {...props}>{name}</StyledIcon>
    </StyledSpan>
  )
}

Icon.defaultProps = {
  size: 'extraLarge',
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  tooltip: PropTypes.bool,
  size: PropTypes.oneOf(keys(theme.fonts.size)),
}

export default Icon
