import PropTypes from 'prop-types'
import styled from 'styled-components'
import Portal from './Portal'


const StyledBackdrop = styled.div`
  display: grid;
  place-items: center;
  place-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background-color: ${({theme}) => theme.overlays.backdrop};
  color: white;
`

const Backdrop = ({children, ...props}) => {

  return (
    <Portal>
      <StyledBackdrop {...props}>
        {children}
      </StyledBackdrop>
    </Portal>
  )
}

Backdrop.propTypes = {
  children: PropTypes.node,
}

export default Backdrop
