import {useTranslation} from 'react-i18next'
import PropTypes from 'prop-types'
import {FormSection} from '../../../uikit'


const FormError = ({submitError, ...props}) => {
  const {t} = useTranslation()

  if (!submitError) return null
  return (
    <FormSection title={t('FormError.title')} color="negative" {...props}>
      {submitError}
    </FormSection>
  )
}

FormError.propTypes = {
  submitError: PropTypes.string,
}

export default FormError
